.video {
    position: relative;
    width: 100%;

    &__container {
        overflow: hidden; 
        position: relative; 
        padding-bottom: 56.25%; 
        width: 100%; 
        height: 100%;

        &--blocked {
            @media (min-width: 200px) and (max-width: 991px) {
                min-height: 400px;
            }
        }
    }

    iframe,
    object,
    embed {
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%;
        border: 0;
    }
}