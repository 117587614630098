/**
*
* Top navigation
*
**/

.l-navbar {
    width: 100%;
    height: 100px;
    background: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);


    display: flex;
    align-items: center;
    flex-flow: row nowrap;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;

    transition: transform 0.5s, box-shadow 0.5s;

    @media screen and (max-width: 1279px) {
        height: 80px;
    }

    &--loading {
        transform: translateY(-100%);
    }

    &--scrolled {
        transform: translateY(-100%);
        box-shadow: none;
    }

    &--submenu {
        z-index: 5;
        box-shadow: none;
    }

    &__logo {
        width: auto;
        height: 65px;

        @media screen and (max-width: 414px) {
            height: 50px;
        }

        @media screen and (min-width: 415px) and (max-width: 1279px) {
            height: 55px;
        }

        &-link {
            display: inline-block;
            padding-left: 30px;

            @media screen and (min-width: 1280px) and (max-width: 1499px) {
                padding-left: 15px;
            }
        }
    }

    &__search {
        display: flex;
        align-items: center;
        height: 100%;
        padding: {
            left: 40px;
            right: 40px;
        }
        background-color: $colorLight;

        @media screen and (min-width: 1500px) and (max-width: 1800px) {
            padding: {
                left: 25px;
                right: 25px;
            }
        }

        @media screen and (min-width: 1280px) and (max-width: 1499px) {
            display: none;
        }

        @media screen and (max-width: 1279px) {
            padding: 36px;
        }

        &-wrapper {
            display: flex;
            align-items: stretch;
            border-bottom: 1px solid $colorMain;

            @media screen and (max-width: 1279px) {
                width: 100%;
            }
        }

        &-input {
            padding: 6px 0;
            border: 0;
            background-color: transparent;

            @media screen and (max-width: 1279px) {
                width: calc(100% - 36px);
            }

            &:focus,
            &:active {
                outline: none;
            }
        }

        &-button {
            background-color: transparent;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.7;
            }
        }

        &-mobile {
            display: flex;
            height: 100%;
            width: 100px;
            justify-content: center;
            align-items: center;
            background-color: $colorLight;

            @media screen and (max-width: 1279px) {
                display: none;
            }

            @media screen and (min-width: 1500px) {
                display: none;
            }

            &:hover {
                svg {
                    opacity: 0.7;
                }
            }

            svg {
                transition: opacity 0.3s;
            }

        }
    }

    &__menu {
        display: flex;
        align-items: center;
        height: 100%;

        @media screen and (max-width: 1279px) {
            display: block;
            height: auto;
        }

        &-wrapper {
            display: flex;
            align-items: center;
            height: 100%;

            @media screen and (min-width: 1280px) {
                display: flex !important;
            }

            @media screen and (max-width: 1279px) {
                display: none;
                width: 100%;
                position: absolute;
                top: 80px;
                left: 0;
                height: auto;
                background-color: #fff;
                border-top: 1px solid $colorLight;
                max-height: calc(100vh - 80px);
                overflow: auto;
                transition: box-shadow 0.5s;
            }

            &.open {
                @media screen and (max-width: 1279px) {
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
                }
            }
        }

        &-item {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;

            @media screen and (max-width: 1279px) {
                justify-content: center;
                flex-direction: column;
            }

            &.open {
                .l-navbar__menu-link {
                    background-color: $colorLight;

                    @media screen and (max-width: 1279px) {
                        background-color: $colorWine;
                        color: #fff;

                        svg {
                            fill: #fff;
                        }
                    }
                }
            }

            &--login {
                @media screen and (min-width: 1800px) {
                    margin-right: 20px;
                }

                @media screen and (min-width: 1279px) and (max-width: 1800px) {
                    min-width: 85px;
                }

                // @media screen and (max-width: 1279px) {
                //     margin-right: 0;
                // }

                .l-navbar__menu-link {
                    color: $colorWine;
                    font-weight: 700;
                    


                    img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;

                        @media screen and (min-width: 1200px) and (max-width: 1800px) {
                            width: 45px;
                            height: 45px;
                        }
                    }
                }
            }
        }
        

        &-link {
            display: flex;
            align-items: center;
            height: 100%;

            padding: 0 20px;
            font-size: 18px;
            color: $colorMain;
            background-color: transparent;
            white-space: nowrap;

            text-align: center;
            border: 0;
            transition: background-color 0.3s;

            @media screen and (min-width: 1581px) and (max-width: 1680px) {
                padding: 0 16px;
            }

            @media screen and (min-width: 1502px) and (max-width: 1580px) {
                padding: 0 14px;
            }

            @media screen and (min-width: 1500px) and (max-width: 1501px) {
                padding: 0 12px;
            }

            @media screen and (min-width: 1280px) and (max-width: 1399px) {
                padding: 0 15px;
            }

            @media screen and (max-width: 1279px) {
                display: block;
                height: auto;
                width: 100%;
                padding: 16px 0;
            }

            &:hover,
            &.active {
                background-color: $colorLight;
            }

            > span,
            > svg {
                pointer-events: none;
            }
        }
    }

    &__sub-menu {
        position: absolute;
        top: 100%;
        left: 0;
        padding: 12px 24px;
        background-color: $colorLight;
        display: none;

        @media screen and (max-width: 1279px) {
            position: static;
            width: 100%;
        }

        &-item {

        }

        &-link {
            display: block;
            padding: 10px 0;
            white-space: nowrap;
            color: $colorMain;
            transition: opacity 0.3s;

            @media screen and (max-width: 1279px) {
                text-align: center;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__spacer {
        flex-grow: 1;
    }
}