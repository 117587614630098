.box-shadowed {
    padding: 35px 15px 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,.2);
    border-top: 5px solid $colorGold;

    @media all and (max-width: 991px) {
        padding: 15px;
    }


    &--lg {
        padding: 40px;
    }
}

.box-center {
    margin: 0 auto;
}

.box-tab {
    position: relative;
    margin-top: -105px;
    box-shadow: 0 10px 20px rgba(0,0,0,.2);
    z-index: 2;
    
    @media all and (min-width: 578px) and (max-width: 767px) {
        margin-top: -75px;
    }

    @media all and (max-widt: 577px) {
        margin-top: -105px;
    }

    &__all-info {
        display: none;

        &--show {

            @media all and (min-width: 767px) {
                display: flex;
            }
        }

        .box-tab__content {
            border-top: 2px solid #EDEDED;
            padding-top: 50px;
        }
    }
    
    &-btn {
        display: flex;

        &__item {
            padding: 32px;
            flex: 1 1 50%;
            border-top: 5px solid $colorRed;
            font-size: 24px;
            font-weight: 700;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: $colorRed;
            color: $colorWhite;

            transition: all 0.3s;

            @media all and (max-width: 767px) {
                padding: 20px;
                font-size: 20px;
            }

            &:hover:not(.box-tab-btn__item--active) {
                border-top: 5px solid $colorWine;
                background-color: $colorWine;
                color: $colorWhite;
            }
        
            &--active {
                background-color: $colorWhite;
                color: $colorMain;
            }
        }

        &--three {

            .box-tab-btn__item {

                @media all and (min-width: 501px) and (max-width: 1023px) {
                    padding: 20px;
                    font-size: 18px;
                }

                @media all and (max-width: 500px) {
                    padding: 20px 10px;
                    font-size: 16px;
                }
            }

            .box-tab-btn__item--active {
                border-top-color: $colorWhite;
            }
        }
    }

    &__info {
        width: 80%;
        margin: 0 auto;
    }

    &__content {
        position: relative;
        height: 100%;

        padding: {
            left: calc(((100vw - 1240px) / 12) + 10px);
            right: calc(((100vw - 1240px) / 12) + 10px);
            bottom: 50px;
        }

        @media all and (min-width: 768px) and (max-width: 1600px) {
            padding: {
                left: calc(((100vw - (100vw - 92%)) / 12) + 10px);
                right: calc(((100vw - (100vw - 92%)) / 12) + 10px);
            }
        }

        @media all and (max-width: 767px) {
            padding: {
                left: calc(((100vw - (100vw - 92%)) / 12) + 10px);
                right: calc(((100vw - (100vw - 92%)) / 12) + 10px);
                bottom: 40px;
            }
            display: flex;
            flex-direction: column;
        }

        &--sm {
            padding: {
                left: calc(((100vw - (100vw - 92%)) / 12) + 10px);
                right: calc(((100vw - (100vw - 92%)) / 12) + 10px);
            }
        }

        &--border-rt {

            &:after {
                position: absolute;
                right: 0;
                top: 0;
                content: "";
                display: inline-block;
                width: 2px;
                height: 80%;
                background-color: #EDEDED;
            }

            &-long {
                &:after {
                    height: 100%;
                }
            }
        }

        &-btn {
            margin-top: 40px;
            width: 290px;
            text-align: center;
            align-self: center;

            @media all and (min-width: 768px) {
                margin-top: 0;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -140px;
                transform: translateY(50%);
            }

            &--wide {
                width: auto;
            }
        }
    }

    &__footer {
        padding: 30px 20px 40px;
        width: 100%;
        text-align: center;


        @media all and (min-width: 767px) {
            padding: 30px 0 0;
            position: absolute;
            bottom: 0;
            transform: translateY(35%);
        }

        .btn {

            @media all and (max-width: 767px) {
                padding: 18px 24px;
                font-size: 20px;
            }
        }
    }
}

.box-list {

    padding-left: 30px;

    li {
        padding: 1px 0 1px 0;
        position: relative;
        margin-bottom: 2px;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 9px;
            left: -30px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $colorGold;

        }
    }

    &--red-bullet {

        li {
            &:before {
                background-color: $colorRed;
            }
        }
    }
}


// .box-img {

    // @media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
    //     min-height: 1px;
    // }

    // img {
        // -ms-interpolation-mode: bicubic;

        // @media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
            // width: 100%;
//         }
//     }
// }

