
.credit-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    padding: rem(26px) rem(24px);
    border-radius: 20px;
    background-color: $colorGold;
    box-shadow: 0 10px 20px rgba(0,0,0,.2);
    color: $colorWhite;

    @media (min-width: 92px) {
        margin-bottom: 2rem;
    }

    &__logo {
        max-width: 130px;
    }

    &__number {
        margin: 1.5rem 0;
        font: {
            size: rem(34px);
            weight: 700;
        }

        @media all and (min-width: 1400px) and (max-width: 1440px) {
            font-size: 1.78rem;
        }

        @media all and (min-width: 1601px) and (max-width: 1720px) {
            font-size: 1.7rem;
        }

        @media all and (min-width: 1721px) and (max-width: 1850px) {
            font-size: 1.9rem;
        }
    }

    &__bottom {
        display: block;
        font: {
            size: rem(20px);
            weight: 400;
        }

        > * {
            display: block;
        }
    }
}