/**
*
* Headlines
*
**/

%commonStyles {
    display: block;
    margin: 0 0 0.4em 0;
    line-height: 1.1;
}

.headline {
    &-1 {
        @extend %commonStyles;
        @include dynamicFontSize(40, 85, 480, 1600);
        font-weight: 700;
    }

    &-2 {
        @extend %commonStyles;
        @include dynamicFontSize(34, 48, 480, 1600);
        font-weight: 700;
    }

    &-3 {
        @extend %commonStyles;
        @include dynamicFontSize(28, 36, 480, 1600);
        font-weight: 700;
    }

    &-4 {
        @extend %commonStyles;
        @include dynamicFontSize(20, 24, 480, 1600);
        font-weight: 700;
    }

    &-5 {
        @extend %commonStyles;
        @include dynamicFontSize(18, 20, 480, 1600);
        font-weight: 700;
    }

    &-6 {
        @extend %commonStyles;
        font-size: 16px;
        font-weight: 700;
    }

    &-7 {
        @extend %commonStyles;
        @include dynamicFontSize(24, 32, 480, 1600);
        font-weight: 700;
    }

    &-8 {
        @extend %commonStyles;
        @include dynamicFontSize(36, 60, 480, 1600);
        font-weight: 700;
    }

    &-9 {
        @extend %commonStyles;
        @include dynamicFontSize(24, 28, 480, 1600);
        font-weight: 700;
    }

    &-normal {
        font-weight: 400;
    }

    &-with-icon {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        @media screen and (max-width: 991px) {
            display: block;
            text-align: center;
        }

        span,
        img {
            @media screen and (max-width: 991px) {
                display: block;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        img {
            @media screen and (min-width: 992px) {
                max-width: 50%;
            }
        }

        span {
            @media screen and (max-width: 991px) {
                margin-bottom: 20px;
            }
        }
    }

    &-with-button {
        // padding-right: 10px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;


        @media all and (min-width: 992px) and (max-width: 1440px) {
            padding-right: 10px;
        }

        @media screen and (max-width: 991px) {
            display: block;
            text-align: center;
        }

        > * {
            margin: {
                top: 0.5rem;
                bottom: 0.5rem;
            }

            @media screen and (max-width: 991px) {
                margin: {
                    top: 1rem;
                    bottom: 1rem;
                }
            }
        }

        .btn {
            flex-shrink: 0;
        }
    }

    &-padded {

        @media all and (max-width: 991px) {
            padding: {
                left: 25px;
                right: 25px;
            }
        }

    }

    &--corporate {
        @media all and (max-width: 768px) {
            display: none;
        }
    }
}
