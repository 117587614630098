/**
*
* List
*
**/

// General
ul,
ol {
    padding: 0;
    margin: 0;
}

ul,
.list-ol,
.list-large {
    list-style-type: none;
}

.list-ul,
.list-ol,
.list-arrows {
    padding-top: 0.7em;
    padding-bottom: 0.7em;

    li {
        padding: 1px 0 1px 0;
        position: relative;
        margin-bottom: 2px;

        &:before {
            position: absolute;
            overflow: hidden;
            display: block;
            content: " ";
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

// Unordered list
.list-ul {
    padding-left: 30px;

    li {
        &:before {
            top: 0;
            left: -30px;

            width: 22px;
            height: 22px;

            content: "•";
            color: $colorGold;
        }
    }

    &--hide {
        display: none;
    }

    & + .list-ul {
        position: relative;
        top: -20px;
    }
}

// Ordered list
.list-ol {
    counter-reset: item;
    padding-left: 35px;

    li {
        &:before {
            left: -35px;

            width: 30px;
            content: counters(item, ".") ". ";
            counter-increment: item;

            text-align: center;
            line-height: 1.5;
            font-weight: 700;
            color: $colorGold;
        }
    }
}

// Other types of lists
.list-2-cols,
.list-3-cols,
.list-4-cols {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    
	li {
        width: 100%;

        margin-bottom: 10px;
	}
}

.list-2-cols {
    li {
        @media (min-width: 501px) {
            width: 47%;
        }
    }
}

.list-3-cols {
    li {
        @media (min-width: 768px) {
            width: 30%;
        }
    }
}

.list-4-cols {
    li {
        @media (min-width: 500px) and (max-width: 991px) {
            width: 50%;
        }

        @media (min-width: 992px) {
            width: 22%;
        }
    }
}

.list-arrows {
    padding-left: 20px;

    li {
        &:before {
            position: absolute;

            top: 0;
            left: -28px;

            width: 24px;
            height: 24px;

            overflow: hidden;
            display: block;
            content: " ";
            background: url(/images/icons/menu-right.svg) center no-repeat/ 24px auto;
        }
    }

    &--gold {
        li {
            &:before {
                background: url(/images/icons/menu-right-gold.svg) center no-repeat/ 24px auto;
            }
        }
    }
}

.list-large {
    
    margin-bottom: 1rem;
    padding: 0 0 0 30px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    counter-reset: itemLarge;
    padding-left: 35px;

    @media (min-width: 200px) and (max-width: 991px) {
        margin-bottom: 2rem;
    }

    > li {
        position: relative;
        white-space: nowrap;
        padding-right: 50px;

        font: {
            size: rem(34px);
            weight: 700;
        }
        color: rgba($colorMain, 0.5);

        @media (min-width: 200px) and (max-width: 991px) {
            font-size: rem(30px);
        }

        &:before {
            position: absolute;
            overflow: hidden;
            display: block;
            left: -35px;

            width: 30px;
            content: counters(itemLarge, ".") ". ";
            counter-increment: itemLarge;

            font: {
                weight: 700;
            }
            color: inherit;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &.active {
            color: $colorWine;
        }

        p {
            margin: 0;
        }
        
    }
}

.content .list--no-bullet,
.list--no-bullet {
    li:before {
        display: none;
    }
}

.list {
    margin: 0 auto;
    max-width: 335px;

    &__item {

        p {
            &:first-child {
                margin-bottom: 0;
                font-size: 20px;
            }

            &:nth-of-type(2) {
                margin-top: 0;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
}

.list-event {

    &__headline {
        transition: all 0.3s;
    }

    &__link {
        display: block;
        text-decoration: none;
        color: $colorDark;

        &:hover {

            .list-event__headline {
                color: $colorRed;
            }
        }
    }
}