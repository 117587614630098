
.popup {
    position: relative;
    //width: 608px;
    width: 680px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 2;

    @media all and (max-width: 640px) {
        width: 90%;
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 2;

        background: none;

        @media all and (max-width: 480px) {
            top: 10px;
            right: 10px;
        }
    }

    &-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 20000;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;

        width: 100%;
        height: 100%;
        background-color: rgba(18, 18, 18, 0.75);

        opacity: 1;
        visibility: visible;
        opacity: 0;
        visibility: hidden;

        transition: opacity 0.8s ease,
                    visibility 0.8s ease;


        &--show {
            opacity: 1;
            visibility: visible;
        }
    }

    &-wrapper-confirmation {
        @media (max-width: 540px) {
            .form__footer {
                position: static;
                transform: none;
            }
        }
    }
}
