.gallery{

    .owl-carousel .owl-nav .owl-prev {
        position: absolute;
        top: 50%;
        left: -15px;
        margin-top: -40px; 

        padding: 35px;
        vertical-align: middle;
        border-radius: 50%;
        background-color: #ffffff;
        transition: box-shadow .3s,background-color .3s;
        box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.2);
        font-size: 0; 

        @media all and (max-width: 767px) {
            margin-top: -30px; 
            padding: 25px;
        }

        &:before {
            position: absolute;
            top: 50%;
            margin-top: -20px;
            left: 50%;
            margin-left: -20px;
            display: inline-block;
            width: 40px;
            height: 40px;
            content: "";
            background: url('/images/icons/chevron-left.svg');
            background-size: cover;

            @media all and (max-width: 767px) {
                margin-top: -12px;
                margin-left: -12px;
                width: 24px;
                height: 24px;
            }
        }

        &:hover {
            box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.15);
        }
    }

    .owl-carousel .owl-nav .owl-next {
        position: absolute;
        top: 50%;
        right: -15px;
        margin-top: -40px; 

        padding: 35px;
        vertical-align: middle;
        border-radius: 50%;
        background-color: #ffffff;
        transition: box-shadow .3s,background-color .3s;
        box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.2);
        font-size: 0;

        @media all and (max-width: 767px) {
            margin-top: -30px; 
            padding: 25px;
        }

        &:before {
            position: absolute;
            top: 50%;
            margin-top: -20px;
            left: 50%;
            margin-left: -20px;
            display: inline-block;
            width: 40px;
            height: 40px;
            content: "";
            background: url('/images/icons/chevron-right.svg');
            background-size: cover;

            @media all and (max-width: 767px) {
                margin-top: -12px;
                margin-left: -12px;
                width: 24px;
                height: 24px;
            }
        }

        &:hover {
            box-shadow: 8px 8px 40px rgba(0, 0, 0, 0.15);
        }
    }
}