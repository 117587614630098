
.full-height-fixed-bg {
    min-height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: {
        top: 25px;
        bottom: 25px;
    }

    position: relative;
    z-index: 1;

    @media screen and (max-width: 991px) {
        min-height: 1px;
    }

    &--align-top {
        align-items: flex-start;
        min-height: 1px;
    }

    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 10%;
        height: 100%;
        width: 80%;
        background: url(/images/icons/background-shape.svg) no-repeat left center / 100% auto;
        opacity: 0.075;

        @media screen and (max-width: 991px) {
            width: 100%;
            left: 0;
        }
    }

    > * {
        position: relative;
        z-index: 2;
    }

    &-wrapper {
        display:flex;
        flex-direction:row;
    }
}
