
.card {
    display: flex;
    align-items: stretch;
    flex-flow: row nowrap;

    @media all and (max-width: 991px) {
        display: block;
        margin: {
            left: 25px;
            right: 25px;
        }
    }

    &--margin-tablet {

        @media all and (min-width: 991px) and (max-width: 1199px) {
            margin: {
                left: 10px;
                right: 10px;
            }
        }
    }

    &--full-height {
        height: 100%;
    }

    &__bt-link {
        margin-top: auto;
        text-align: right;
    }

    &--flex {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &--shadow {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }

    &--featured {
        position: relative;
        z-index: 1;

        &:after {
            content: ' ';
            position: absolute;
            top: -10px;
            left: 20px;

            width: 70px;
            height: 102px;

            background: url(/images/icons/featured.svg) center no-repeat;
            background-size: 100% auto;
        }

        &.card--narrow-img.card--swap {
            &:after {
                left: calc(75% - 80px);

                @media all and (max-width: 991px) {
                    left: 20px;
                }
            }

            .card__content {
                border-top: 5px solid $colorGold;
            }
        }

        &-webinar {

            &:after {
                left: calc(50% - 80px) !important;

                @media all and (max-width: 991px) {
                    left: 20px !important;
                }
            }

            .card__content {
                @media all and (min-width: 992px) {
                    width: 50% !important;
                }
            }

            .card__image {
                @media all and (min-width: 992px) {
                    width: 50% !important;
                    padding-bottom: 28% !important;
                }
            }
        }
    }

    &--normal {
        display: block;
    }

    &--footer {
        @media all and (max-width: 991px) {
            margin-bottom: 2.5rem;
        }
    }

    &--inline {


        &-small {
            .card__image {
                @media all and (min-width: 992px) {
                    width: 30%;
                    padding-bottom: 42%;
                }
            }

            .card__content {
                @media all and (min-width: 992px) {
                    width: 70%
                }
            }
        }

        &-medium {
            .card__image {
                @media all and (min-width: 992px) {
                    width: 40%
                }
            }

            .card__content {
                @media all and (min-width: 992px) {
                    width: 60%
                }
            }
        }
    }

    &--swap {
        .card__image {
            @media all and (min-width: 992px) {
                order: 2;
            }
        }

        .card__content {
            @media all and (min-width: 992px) {
                order: 1;
            }
        }
    }

    &--narrow-img {
        .card__image {
            @media all and (min-width: 992px) {
                width: 25%;
                padding-bottom: 35%;
            }
        }

        .card__content {
            @media all and (min-width: 992px) {
                width: 75%
            }
        }
    }

    &__image,
    &__content {
        width: 50%;

        &--70 {
            width: 70%;
        }

        .card--normal &,
        .card--flex & {
            width: 100%;
        }

        @media all and (max-width: 991px) {
            width: 100%;
        }
    }

    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;

        .card--normal &,
        .card--flex & {
            padding-bottom: 56.25%;
        }

        .card--featured & {
            @media all and (min-width: 992px) {
                // padding-bottom: calc(56.25% / 2);
                padding-bottom: 35%;
            }
        }

        @media all and (max-width: 991px) {
            padding-bottom: 56.25%;
        }

        &--contain {
            background-size: auto 80%;
            background-position: center center;

            padding: {
                top: 2rem;
                bottom: 2rem;
                left: 25px;
                right: 25px;
            }

            @media all and (max-width: 991px) {
                padding-bottom: 56.25%;
            }
        }

        &--border-top {
            border-top: 5px solid $colorGold;

            padding: {
                top: 2rem;
                bottom: 2rem;
                left: 25px;
                right: 25px;
            }

            + .card__content {
                @media all and (min-width: 992px) {
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                }

                @media all and (max-width: 991px) {
                    border-top: 0;
                }
            }
        }

        &-inline {
            align-self: center;

            @media all and (max-width: 991px) {
                margin: 0;
                padding: {
                    top: 2rem;
                    left: 25px;
                    right: 25px;
                }
                border-top: 5px solid $colorGold;
            }

            + .card__content {
                @media all and (max-width: 991px) {
                    border-top: 0;
                }
            }
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        padding: 36px;
        border-top: 5px solid $colorGold;

        @media all and (max-width: 991px) {
            padding: 24px;
        }

        &--flex {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        &-btn {
            @extend .form__footer;
        }

        &--padding-sm {
            padding: 20px;

            &.card__content--with-footer  .card__footer {
                @media all and (min-width: 992px) {
                    padding-bottom: 20px;
                }
            }
        }

        > * {
            transition: opacity 0.3s;
        }

        &--with-footer {
            @media all and (min-width: 992px) {
                padding-bottom: 110px;
            }

            .card__footer {
                @media all and (min-width: 992px) {
                    position: absolute;
                    z-index: 2;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    padding: {
                        left: inherit;
                        right: inherit;
                        bottom: 36px;
                    }
                }
            }

            &-small {

                @media all and (max-width: 991px) {
                    padding-bottom: 20px;
                }

                @media all and (min-width: 992px) {
                    padding-bottom: 74px;
                }

                .card__footer {
                    @media all and (min-width: 992px) {
                        position: absolute;
                        z-index: 2;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        padding: {
                            left: inherit;
                            right: inherit;
                            bottom: 20px;
                        }
                    }
                }
            }
        }

        &--padded-mob {

            @media all and (max-width: 991px) {
                padding-bottom: 50px;
            }
        }

        &--border-none {
            border-top: none;
        }

        .content--medium-text {
            height: 79px;
            overflow: hidden;
            // position: relative;

            // &:after {
            //     content: "";
            //     text-align: right;
            //     position: absolute;
            //     bottom: 0;
            //     right: 0;
            //     width: 50%;
            //     height: 1.2em;
            //     background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
            // }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 80px;

        @media all and (max-width: 991px) {
            padding-top: 16px;
        }

        .card--normal &,
        .card--inline-small & {
            padding-top: 16px;
        }

        .ico-label-list {
            margin: {
                top: 10px;
                bottom: 0;
            }
        }

        .link {
            margin-top: 10px;
            
            @media all and (max-width: 440px) {
                white-space: nowrap;
            }
        }

        &--right {
            justify-content: flex-end;

            @media all and (max-width: 991px) {
                display: block;
                text-align: right;
            }
        }
    }

    &--white  {

        .card {

            &__content {
                background-color: $colorWhite;
            }
    
            &__footer {
               
                span.link {
                    color: $colorWine;
                }

                .chip {
                    background-color: $colorWine;
                    color: $colorWhite;
                }
            }
        }

        .ico-label__icon svg {
            fill: $colorWine;
        }
    }   

    @at-root {
        a#{&} {
            color: $colorMain;
            transition: box-shadow 0.3s;

            &:hover {
                box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15);

                .card__content {
                    > *:not(.card__content-btn) {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}
