
.one-line-form {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 50px;

    @media screen and (max-width: 768px) {
        display: block;
        border-radius: 0;
        box-shadow: none;
    }

    &--margin {
        margin-top: 2rem;

        @media screen and (max-width: 768px) {
            margin-top: 1rem;
        }
    }

    &__input {
        width: 100%;
        padding: 20px 36px;
        border-radius: 50px 0 0 50px;
        border: 0;

        font: {
            size: rem(24px);
            weight: 700;
        }

        @media screen and (max-width: 768px) {
            display: block;
            margin-bottom: 1rem;
            border-radius: 50px;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        }

        &:focus {
            outline: none;
        }
    }

    &__button {
        padding: 20px 36px;
        background-color: darken($colorGold, 10%);
        border-radius: 0 50px 50px 0;
        border: 0;
        white-space: nowrap;

        font: {
            size: rem(24px);
            weight: 700;
        }
        color: #fff;
        line-height: rem(28px);

        transition: background-color 0.3s;

        &:hover {
            background-color: $colorWine;
        }

        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
            border-radius: 50px;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        }
    }
}