
.chip {
    display: inline-block;
    margin: 10px 10px 0 0;
    padding: 6px 10px 5px 10px;
    border-radius: 12px;
    font: {
        size: 15px;
        weight: 400;
    }
    line-height: 1;
    box-shadow: none;

    @each $colorName, $color in $colors {
        &--#{$colorName} {
            background-color: $color;
            
            @if map-has-key($bgColorsWhiteFont, $colorName) {
                color: #fff;
            }

            @else {
                color: $colorMain;
            }
        }
    }

    &--with-image {
        display: inline-flex;
        align-items: stretch;
        padding: 0;
        border-radius: 0;

        img {
            margin: 0 10px 0 -5px;
            height: 40px;
            width: 40px;
            border-radius: 40px;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-right: 15px;
        @supports(justify-content: space-evenly) {
            justify-content: space-evenly;
        }
        font-size: rem(14px);

        span {
            color: $colorMain;

            &:last-child {
                color: rgba($colorMain, 0.7);
            }
        }
    }

    &--two-lines {
        .chip__text {
            min-height: 40px;
            margin-left: 15px;
        }
    }

    &-inline-wrapper {
        display: flex;

        @media all and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    @at-root {
        a#{&} {
            transition: box-shadow 0.3s;

            &:hover {
                box-shadow: 0 5px 10px rgba(0,0,0,.15), 0 5px 20px rgba(0,0,0,.15);
            }
        }
    }

    &--corporate {
        margin: 0;
            @media all and (max-width: 768px) {
                display: none;
            }
    }
   
}