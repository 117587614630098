
.product {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 24px;
    background-color: #fff;

    &__info {
        color: $colorMain;

        .product--inactive & {
            position: relative;
            padding-left: 60px;
            color: rgba($colorMain, 0.4);

            &:before {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 48px;
                height: 48px;

                background: url(/images/icons/lock.svg) center no-repeat;
                background-size: 100% auto;

                opacity: 0.36;
            }
        }
    }

    &__quantity {
        min-width: 120px;
        color: $colorMain;

        &--inactive {
            pointer-events: none;

            .sbSelector {
                color: rgba($colorMain, 0.4);
    
                &:link,
                &:visited,
                &:hover {
                    color: rgba($colorMain, 0.4);
                }
    
                &:after {
                    opacity: 0.36;
                }
            }
        }

        .product--inactive & {
            color: rgba($colorMain, 0.4);

            @media screen and (max-width: 991px) {
                display: none;
            }
        }
    }

    &__name {
        margin: 0;
        font: {
            size: rem(20px);
            weight: 700;
        }
        line-height: rem(24px);

        @media screen and (max-width: 991px) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &__price {
        margin: 0;
        font: {
            size: rem(14px);
            weight: 700;
        }
        line-height: rem(17px);

        @media screen and (max-width: 991px) {
            font-size: 12px;
            line-height: 15px;
        }
    }

    &__selected {
        opacity: 0;
        visibility: hidden;
        font: {
            size: rem(18px);
            weight: 700;
        }
        line-height: rem(22px);
        letter-spacing: 1px;
        transition: opacity 0.3s, visibility 0.3s;
    }

    &--shadow {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }

    &--reset {
        margin: 0;
        padding: 0;
        background-color: transparent;
    }

    &--inactive,
    .block--blocked & {
        color: rgba($colorMain, 0.4);
        pointer-events: none;

        .sbSelector {
            color: rgba($colorMain, 0.4);

            &:link,
            &:visited,
            &:hover {
                color: rgba($colorMain, 0.4);
            }

            &:after {
                opacity: 0.36;
            }
        }
    }
}