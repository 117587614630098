.overlay {
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    @each $colorName, $color in $colors {
        &--#{$colorName} {
            &:after {
                background-color: rgba($color, 0.8);
            }
        }
    }
}