.plan {

    @media all and (max-width: 991px) {
        padding-bottom: 50px;
        border-bottom: 2px solid #EDEDED;
    }

    &:first-child {
        &:after{
            position: absolute;
            right: 0;
            top: 50px;
            content: "";
            display: inline-block;
            width: 2px;
            height: 80%;
            background-color: #ededed;
        }
    }

    &__btn {
        @media all and (min-width: 992px) {
            display: none;
        }
    }

    &--col-last {

        @media all and (max-width: 991px) {
            padding-bottom: 80px;
        }

        .plan__item {
            border-right: none;
        }
    }

    &-container {
        position: relative;
        margin-top: -105px;
        box-shadow: 0 10px 20px rgba(0,0,0,.2);
        z-index: 2;
    
        @media all and (min-width: 578px) and (max-width: 767px) {
            margin-top: -75px;
        }

        @media all and (max-widt: 577px) {
            margin-top: -105px;
        }

        &__footer {
            padding: 30px 20px 0;
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 0;
            transform: translateY(35%);
        
            .btn {
                
                @media all and (max-width: 767px) {
                    padding: 18px 24px;
                    font-size: 20px;
                }
            }
        }
    }

    &__item {
        position: relative;
        padding: {
            right: calc(((100vw - (100vw - 92%)) / 12) + 10px);
            bottom: 50px;
            left: calc(((100vw - (100vw - 92%)) / 12) + 10px);
        }

        @media all and (max-width: 576px) {

            padding: {
                right: 20px;
                bottom: 0;
                left: 20px;
            }
        }

        &:not(:last-child) {

            @media all and (min-width: 992px) {
                border-bottom: 2px solid #EDEDED;
            }
        }

        &:not(:first-child) {

            @media all and (min-width: 992px) {
                border-right: 2px solid #EDEDED;
                padding-top: 50px;
            }
        }

        &:last-child {

            @media all and (min-width: 992px) {
                padding-bottom: 80px;
            }
        }

        &:first-child {
            margin-top: 50px;

            @media all and (max-width: 991px) {
                margin-bottom: 50px;
            }
        }

        &--empty {

            @media all and (max-width: 991px) {
                display: none;
            }
        }
    }
}


