
svg {
    pointer-events: none;
}

.svg {

    @each $color, $color_code in $colors {
        &-fill-#{$color} {
            fill: $color_code;
        }

        &-stroke-#{$color} {
            stroke: $color_code;
        }
    }

    @each $color, $color_code in $colors {
        &-#{$color} {
            fill: $color_code;
        }
    }
}