
$mainContainerMaxWidth: 1240;
$mediumContainerMaxWidth: 1398;
$narrowContainerMaxWidth: 924;
$tinyContainerMaxWidth: 600;
$superNarrowContainerMaxWidth: 608;
$largeContainerMaxWidth: 1556;

.container {
    position: relative;
    margin-right: auto;
    margin-left: auto;

    width: 92%;
    max-width: ($mainContainerMaxWidth)+px;

    &-tiny {
        @extend .container;
        max-width: ($tinyContainerMaxWidth)+px;
    }

    &-super-narrow {
        @extend .container;
        max-width: ($superNarrowContainerMaxWidth)+px;
    }

    &-narrow {
        @extend .container;
        max-width: ($narrowContainerMaxWidth)+px;
    }

    &-medium {
        @extend .container;
        max-width: ($mediumContainerMaxWidth)+px;
    }

    &-large {
        @extend .container;
        max-width: ($largeContainerMaxWidth)+px;
    }

    &-fluid {
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;
    }
}