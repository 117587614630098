.map {
    height: 450px;
    width: 100%;

    &-box {
        position: relative;

        &--shadow {
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        }
    }
}


