/**
*
* Main footer
*
**/

.l-footer {
    position: relative;
    z-index: 3;
    padding: 50px 0;
    box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.20);

    @media screen and (max-width: 991px) {
        padding: 50px 20px;
        text-align: center;
    }

    &__logo {


        &-link {
            display: inline-block;
            margin-bottom: 1rem;
        }
    }

    &__para {
        margin-top: 0;
        font: {
            size: 16px;
            weight: 400;
        }
    }

    &__link {
        color: $colorMain;
        transition: opacity 0.2s;

        &:hover {
            opacity: 0.7;
        }
    }

    &__menu {
        display: flex;

        @media screen and (max-width: 768px) {
            display: block;
        }

        &-list {
            @media screen and (max-width: 768px) {
                margin-top: 1.5rem;
            }
        }

        &-col {
            flex-grow: 1;

            &:not(:first-child) {
                @media screen and (max-width: 768px) {
                    margin-top: 1rem;
                }
            }
        }

        &-link {
            display: block;
            width: 100%;
            padding-bottom: 6px;
            color: $colorMain;
            transition: color 0.2s;

            @media screen and (max-width: 768px) {
                padding-bottom: 20px;
            }
    
            &:hover {
                color: $colorWine;
            }
        }
    }

    &__bp {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-self: flex-end;

        font-size: 14px;

        @media screen and (max-width: 991px) {
            justify-content: center;
        }

        span {
            padding-right: 0.3rem;
        }

        img,
        svg {
            max-width: 116px;
        }
    }

    &__logo-col {
        display: flex;
        flex-flow: row wrap;

        @media screen and (max-width: 991px) {
            display: block;
        }
    }

    .social-media {
        margin-top: 1.5rem;
    }

    [class*="col-"] {
        @media screen and (max-width: 991px) {
            margin-bottom: 2rem;
        }
    }
}