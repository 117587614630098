/*==========  GLOBAL STYLES  ==========*/

html {
    box-sizing: border-box;
}

*, 
*:before, 
*:after {
    box-sizing: inherit;
}

hr {
    border: 1px solid white;
}

html,
body,
button {
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

html {
    background: #fff;
    overflow-x: hidden;

    &.nav-open {
        @media screen and (max-width: 1023px) {
            overflow: hidden;
        }
    }
}

body {
    font-family: "SofiaPro", sans-serif;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    color: $colorMain;
    overflow-x: hidden;
    margin-top: 100px;
    background-color: #fff;

    &.nav-open {
        @media screen and (max-width: 1023px) {
            overflow: hidden;
        }
    }

    @media screen and (max-width: 1279px) {
        margin-top: 80px;
    }
}

ul, li {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;

    &:not([class]) {
        color: inherit;
        text-decoration: underline;
        transition: opacity 0.2s;

        &:hover {
            opacity: 0.7;
        }
    }
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

input {
    &[disabled] {
        background-color: #ededed;
        opacity: 0.6;
    }
}