.tweet {
    position: relative;
    height: 100%;
    box-shadow: 0 7px 20px rgba(0,0,0,.2);

    display: flex;
    flex-direction: column;

    &__logo {
        margin-right: 8px;
        // padding: 5px;
        width: 48px;
        height: 48px;
        background: $colorWine;
        border-radius: 50%;
        border: 5px solid $colorWine;
        overflow: hidden;

        @media all and (max-width: 1024px) {
            width: 35px;
            height: 35px;
        }

        svg {
            width: 38px;
            height: 38px;

            @media all and (max-width: 1024px) {
                width: 27px;
                height: 27px;
            }
        }
    }

    &__header {
        padding: 24px;
        display: flex;
        align-items: center;

        @media all and (max-width: 480px) {
            padding: 16px;
        }

        &:hover {

            .tweet__account-name {
                color: $colorWine;
            }
        }
    }

    &__account {
        color: $colorMain;

        &-name {
            transition: all 0.3s;

            @media all and (max-width: 1024px) {
                font-size: 16px;
            }
        }

        * {
            margin: 0;
            line-height: 1;
        }

        span {
            font-size: 14px;

            @media all and (max-width: 1024px) {
                font-size: 12px;
            }
        }
    }

    &__icon {
        position: absolute;
        top: 25px;
        right: 24px;
        fill: $colorTweeterBlue;

        @media all and (max-width: 480px) {
            top: 14px;
            right: 14px;
        }
    }

    &__img {
        margin: 0;

        background-repeat: no-repeat;
        background-position: center;

        @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
            height: 100%;
        }

        img {
            visibility: hidden;
        }
    }

    &__main {
        padding: 24px;

        @media all and (max-width: 480px) {
            padding: 16px;
        }

        p {
            margin-top: 0;
        }

        a {
            color: $colorTweeterBlue;
            transition: color 0.3s ease;

            &:hover {
                color: $colorWine;
            }
        }
    }

    &__footer {
        padding: 24px;
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media all and (max-width: 480px) {
            padding: 16px;
        }

        &-item {
            color: rgba(18, 18, 18, 0.5);
            font-size: 14px;
        }
    }
}

.tweet-icons {
    display: flex;

    &__item {

        &:not(:last-child) {
            margin-right: 24px;
        }
    }

    &__btn {
        padding: 0;
        background: transparent;

        svg {
            transition: all 0.3s;
        }

        &:hover {

            svg {
                fill:  $colorBlue;
            }
        }
    }
}