
.fixed-bar {
    display: none;

    @media screen and (max-width: 991px) {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 4;
        display: block;
        width: 100%;
        text-align: center;
        border-top: 1px solid white;
    }

    > a {
        &:not([class]) {
            display: block;
            padding: 24px;
        }
    }
}
