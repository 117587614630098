.flex {
    display: flex;
    width: 100%;
    flex-flow: wrap row;
    align-items: stretch;
    justify-content: flex-start;

    > [class*="col-"] {
        flex-direction: row;
        flex-flow: nowrap row;
        float: none;
    }

    &--auto {
        > * {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }

    &--top {
        align-items: flex-start;

        > [class*="col-"] {
            align-self: flex-start;
        }
    }

    &--bottom {
        align-items: flex-end;
    }

    &__spacer {
        flex-grow: 1;
    }

    &--center {
        justify-content: center;

        > [class*="col-"] {
            align-self: center;
        }
    }

    &--middle {
        align-items: center;
    }

    &--between {
        justify-content: space-between;

        > * {
            margin: {
                top: 0.5rem;
                bottom: 0.5rem;
            }
        }
    }

    &--sm-center {
        
        @media all and (max-width: 576px) {
            flex-direction: column;
            justify-content: center;
            text-align: center;

        }
    }

    &--sm-col {

        @media all and (max-width: 991px) {
            flex-direction: column;
            text-align: center;
        }
    }

    &__item {

        &-45 {
            flex-basis: 45%;
        }
    }

    &-col {
        display: flex;
        flex-direction: column;

        &__item {
            flex: 1 1 50%;

            a {
                height: 100%;
            }
        }
    }
}