.logo-block {
    &:not(:last-child) {
        margin-bottom: 3em;
    }

    &__image {
        margin: 1em 0;

        img {
            max-height: 100px;
        }
    }
}