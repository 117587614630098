/**
*
* Media - imgs/videos
*
**/

img,
video,
object {
    max-width: 100%;
}

img:not([src$=".svg"]) {
    height: auto;
}
