
.block {
    display: block;
    padding: rem(48px);

    @media screen and (max-width: 991px) {
        padding: rem(24px);
    }

    + .block {
        margin-top: rem(48px);
    }

    .align-items-lg-stretch & {
        display: flex;
        align-items: center;
        height: 100%;

        > * {
            width: 100%;
        }
    }

    &--shadow {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }

    &--mobile-margin {
        @media screen and (max-width: 991px) {
            margin: {
                left: 25px;
                right: 25px;
            }
        }

        &:last-child {
            @media screen and (max-width: 991px) {
                margin-bottom: 2rem;
            }
        }
    }

    &--tablet-margin {

        @media all and (min-width: 991px) and (max-width: 1199px) {
            margin: {
                left: 15px;
                right: 15px;
            }
        }
    }

    &--one-col-padding {
        padding: {
            left: calc((100vw / 12) + 10px);
            right: calc((100vw / 12) + 10px);
            top: 96px;
            bottom: 96px;
        }

        @media screen and (max-width: 768px) {
            padding: {
                top: 48px;
                bottom: 48px;
            }
        }
    }

    &--no-padding {
        padding: 0;

        @media screen and (max-width: 991px) {
            padding: 0 rem(24px);
        }

        &-all {
            padding: 0;

            .form__footer {
                padding-right: rem(48px);
    
                @media screen and (max-width: 991px) {
                    padding-right: 0; // rem(24px);
                }
            }

            &.form {
                @media screen and (max-width: 991px) {
                    padding-bottom: rem(24px);
                }
    
                .block__content {
                    &:last-of-type {
                        padding-bottom: 0;
                    }
                }
            }
        }

        &.form {
            @media screen and (max-width: 991px) {
                padding-bottom: rem(24px);
            }

            .block__content {
                &:last-of-type {
                    padding-bottom: 0;
                }
            }
        }

        .form__footer {
            padding-right: rem(48px);

            @media screen and (max-width: 991px) {
                padding-right: 0; // rem(24px);
            }
        }
    }

    &--padding {
        &-sm {
            padding: rem(24px);
        }
    }

    &--blocked {
        position: relative;
        z-index: 1;

        &-overlay {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: rem(48px);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background-color: rgba($colorWine, 0.9);
        
            @media screen and (max-width: 991px) {
                padding: rem(24px);
            }

            svg {
                margin-bottom: 2rem;
            }
        }

        .form__footer {
            display: none;
        }
    }

    &__headline,
    &__content {
        padding: rem(48px);
        border-top: 2px solid #ededed;
    
        @media screen and (max-width: 991px) {
            padding: rem(24px);
        }

        &:first-child {
            border-top: 0;
        }
    }

    &__headline {
        > * {
            &:first-child {
                margin-bottom: 0;
            }
        }
    }

    &__free-text {
        margin: 2em 0 0 0;
    }

    &-img {
        background-position: center;
        background-repeat: no-repeat;

        @media all and (min-width: 1200px) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;
        }

        &-wrapper {

            &:before {

                @media all and (min-width: 1200px) {
                    content: "";
                    display: block;
                    padding-top: 75%;
                    height: 0;
                }
            }
        }

        img {
            visibility: hidden;

            @media all and (max-width: 991px) {
                visibility: visible;
            }
        }
    }
}


.block-event {
    padding: 24px;
    height: 100%;
    box-shadow: 0 7px 20px rgba(0,0,0,.2);

    display: flex;
    flex-direction: column;

    &__footer {
        margin-top: auto;
        text-align: right;

        &--flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    p {
        margin: 0;
    }

    .btn {

        @media all and (max-width: 1080px) {
            font-size: rem(16px);
        }
    }
}

.block-top {
    position: relative;
    margin-top: -105px;


    z-index: 10;

    @media all and (min-width: 578px) and (max-width: 767px) {
        margin-top: -75px;
    }

    @media all and (max-widt: 577px) {
        margin-top: -105px;
    }
}

.block-pagination {
    padding: 36px 36px 20px;
    box-shadow: 0 10px 20px rgba(0,0,0,.2);
    background-color: $colorWhite;

    @media all and (max-width: 768px) {
        padding: {
            left: 25px;
            right: 25px;
        }
    }
}

.block-logo {
    padding: rem(24px);
    height: 100%;
    box-shadow: 0 7px 20px rgba(0,0,0,.2);

    // display: flex;
    // flex-direction: column;
         
    &__icon {
        height: 100px;
        vertical-align: middle;
        line-height: 100px;

        // display: flex;
        // align-items: center;
        // justify-content: center;
        margin-bottom: 1rem;

        &--corporate {
            @media all and (max-width: 768px) {
                margin-bottom: 0;
            }
        }    

        img {
            max-height: 80px;
            width: auto; 
            height: auto;

            // @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
            //     width: 100%;
            // }
        }
    }
    &--corporate {
        cursor: pointer;
        
        @media all and (max-width: 768px) {
            padding: rem(4px);
            height: auto;
            margin-bottom: 0;
        }
   }   
}