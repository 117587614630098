.social-media {
    &__item,
    &__link {
        display: inline-block;
        vertical-align: middle;
    }

    &__item {
        &:not(:last-child) {
            margin-right: 20px;
        }

        &:hover {
            .social-media__icon {
                opacity: 0.65;
            }
        }
    }

    &__icon {
        width: 30px;
        height: 30px;

        transition: opacity 0.3s;
    }

    &-icon {
        display: inline-block;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.65;
        }
    }
}