.accordion-info {
    position: relative;
    margin-bottom: 20px;
    display: none;
    padding: 36px;
    box-shadow: 0 7px 20px rgba(0,0,0,.2);

    @media all and (max-width: 767px) {
        padding: 40px 20px;
    }

    &__close {
        position: absolute;
        top: 18px;
        right: 18px;
        cursor: pointer;
        background-color: transparent;

        svg {
            width: 24px;
            height: 24px;
        }
    }
}