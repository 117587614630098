
.itinerary {
    list-style-type: none;

    &__item {
        position: relative;
        background-color: white;
        padding: 24px 130px 24px 24px;
        font: {
            size: 18px;
            weight: 400;
        }

        &:nth-child(even) {
            background-color: $colorLight;
        }

        &--title {
            padding: 24px;
            background-color: $colorWine;
            color: white;
            font: {
                size: 24px;
                weight: 700;
            }
        }
    }

    &__time {
        position: absolute;
        top: 24px;
        right: 24px;
    }

    
}