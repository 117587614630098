/**
*
* Variables
*
**/

$columnsWidthSet: (10, 20, 25, 30, 33, 40, 50, 60, 67, 70, 75, 80, 90);

/* Colors */

$colorDark: #121212;
$colorHalfDark: rgba($colorDark, 0.5);
$colorMain: $colorDark;
$colorRed: #A21616;
$colorWine: #B92222;
$colorRose: #C92F08;
$colorWhite: white;
$colorSmokyWhite: rgba(255, 255, 255, 0.85);
$colorGold: #C28957;
$colorLight: #f1f1f1;
$colorBlue: #4099ff;
$colorTweeterBlue: #1DA1F2;

$socialMediaColors: (
    twitter: #4099ff, 
    linkedin: #007bb6, 
    facebook: #3b5998, 
    googleplus: #dd4b39, 
    email: #3bb55e, 
    instagram: #e1306c
);

$colors: (
    main: $colorMain,
    white: $colorWhite,
    red: $colorRed,
    wine: $colorWine,
    smokyWhite: $colorSmokyWhite,
    gold: $colorGold,
    dark: $colorDark,
    halfDark: $colorHalfDark,
    light: $colorLight,
    blue: $colorBlue,
    rose: $colorRose
);

$bgColorsWhiteFont: (
    red: $colorRed,
    wine: $colorWine,
    gold: $colorGold,
    dark: $colorDark,
    rose: $colorRose
);