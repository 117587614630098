/**
*
* Table
*
**/

.table {
    text-align: left;

    td,
    th {
        padding: 10px;
        border: 1px solid #ccc;
        border-collapse: collapse;
    }

    thead {
        background: #ccc;
        color: #666;
        font-weight: 600;
    }
}

.table-event {

    &__th {
        font-weight: bold;
        flex: 0 0 65px;

        @media all and (min-width: 768px) {
            display: none;
        }
    }


    &__row {
        display: flex;

        @media all and (max-width: 768px) {
            flex-direction: column;

            @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
                height: 160px;
            }
        }

        &:first-child {

            @media all and (max-width: 768px) {
                display: none;
            }
        }

        &:not(:last-child) {
            margin-bottom: 15px;

            @media all and (max-width: 768px) {
                margin-bottom: 25px;
            }
        }

        .table-event__col {

            &:first-child {
                flex: 1 1 40%;

                @media all and (max-width: 768px) {
                    margin-bottom: 5px;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }

            &:nth-of-type(2) {
                flex: 1 1 24%;
            }

            &:nth-of-type(3) {
                flex: 1 1 10%;
            }

            &:nth-of-type(4) {
                flex: 1 1 10%;
            }

            &:last-child {
                flex: 1 1 15%;
                min-width: 140px;
                text-align: center;

                @media all and (max-width: 768px) {
                    align-self: flex-end;
                    min-width: auto;
                }

                a {
                    @media all and (max-width: 768px) {
                        font-size: 16px;
                    }
                }
            }

            &:nth-child(n+1):nth-child(-n+4) {

                @media all and (min-width: 768px) {
                    margin-right: 5%;
                }
            }

            &:not(:first-child) {
                @media all and (max-width: 768px) {
                    display: flex;
                }
            }
        }
    }
}

.table-invoice {

    &__th {
        font-weight: bold;
        flex: 0 0 65px;

        @media all and (min-width: 768px) {
            display: none;
        }
    }


    &__row {
        display: flex;

        @media all and (max-width: 768px) {
            flex-direction: column;

            @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
                height: 100px;
            }
        }

        &:first-child {

            @media all and (max-width: 768px) {
                display: none;
            }
        }

        &:not(:last-child) {
            margin-bottom: 15px;

            @media all and (max-width: 768px) {
                margin-bottom: 25px;
            }
        }

        .table-invoice__col {

            &:first-child {
                flex: 1 1 40%;

                @media all and (max-width: 768px) {
                    margin-bottom: 5px;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }

            &:nth-of-type(2) {
                flex: 1 1 30%;
            }

            &:nth-of-type(3) {
                flex: 1 1 20%;
            }

            &:nth-of-type(4) {
                flex: 1 1 10%;
            }

            &:last-child {
                margin-right: 1%;
                flex: 1 1 10%;
                min-width: 120px;
                text-align: center;

                @media all and (max-width: 768px) {
                    align-self: flex-end;
                    min-width: auto;
                }

                a {
                    @media all and (max-width: 768px) {
                        font-size: 16px;
                    }
                }
            }

            &:nth-child(n+1):nth-child(-n+4) {

                @media all and (min-width: 768px) {
                    margin-right: 5%;
                }
            }

            &:not(:first-child) {
                @media all and (max-width: 768px) {
                    display: flex;
                }
            }
        }
    }
}

.table-flex {

    &__th {
        font-weight: bold;
        flex: 0 0 65px;

        @media all and (min-width: 769px) {
            display: none;
        }
    }

    &__col {

        &:first-child {
            @media all and (max-width: 768px) {
                margin-bottom: 5px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        &:last-child {
            @media all and (max-width: 768px) {
                text-align: right;
            }
        }

        &--10 {
            flex: 1 1 10%;
        }

        &--25 {
            flex: 1 1 25%;
        }

        &--40 {
            flex: 1 1 40%;
        }
    }


    &__row {
        display: flex;

        @media all and (max-width: 768px) {
            flex-direction: column;

            @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
                height: 100px;
            }
        }

        &:first-child {

            @media all and (max-width: 768px) {
                display: none;
            }
        }

        &:not(:first-child) {

            @media all and (max-width: 480px) {
                font-size: 16px;
            }
        }

        &:not(:last-child) {
            margin-bottom: 15px;

            @media all and (max-width: 768px) {
                margin-bottom: 25px;
            }
        }

        a {

            @media all and (max-width: 480px) {
                font-size: 16px;
            }
        }
    }
}
