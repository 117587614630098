
.summary {
    width: 100%;
    margin: 2rem 0;

    &__head {
        font: {
            size: rem(24px);
            weight: 700;
        }
        text-align: left;

        th {
            padding-bottom: rem(8px);
        }
    }

    &__body {
        font: {
            size: rem(18px);
            weight: 400;
        }

        td {
            padding: 6px 0;

            &:last-child {
                text-align: right;
            }
        }

        tr {
            &:last-child {
                td {
                    padding-bottom: 16px;
                }
            }
        }
    }

    &__footer {
        font: {
            size: rem(18px);
            weight: 400;
        }

        tr {
            border-top: 2px solid white;
        }

        td {
            padding-top: 16px;
            font-weight: 700;

            &:last-child {
                text-align: right;
            }
        }
    }
}