
.avatar {
    display: block;
    width: 96px;
    height: 96px;
    border-radius: 96px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;

    @media all and (min-width: 200px) and (max-width: 767px) {
        width: 70px;
        height: 70px;
        border-radius: 70px;
    }

    @media screen and (max-width: 991px) {
        margin: {
            left: auto;
            right: auto;
        }
    }

    &--small {
        width: 80px;
        height: 80px;
        border-radius: 80px;
        border: 0;
    }

    &__info {
        .avatar + & {
            margin-left: 24px;

            @media screen and (max-width: 991px) {
                margin-left: 0;
            }
        }

        font: {
            size: rem(20px);
        }

        @media screen and (max-width: 991px) {
            text-align: center;
        }

        &--bottom {
            align-self: flex-end;
            margin-bottom: 1rem;
        }
    }

    &__headline {
        margin-top: 0;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        font: {
            size: 24px;
            weight: 400;
        }

        @media screen and (max-width: 991px) {
            display: block;
            text-align: center;
        }
    }

    &__subheadline {
        margin: 0 0 8px 0;
        font: {
            size: 20px;
            weight: 400;
        }

        @media screen and (max-width: 991px) {
            text-align: center;
        }
    }

    &__description {
        margin: 0;
        padding: 0;
        font-size: rem(18px);
        line-height: 1.2;
    }

    &__link {
        display: block;
        margin-top: 4px;
        font-size: rem(18px);
        line-height: 1.2;
        color: $colorMain;

    }

    &-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        @media screen and (max-width: 991px) {
            display: block;
        }

        &--center {
            justify-content: center;
        }
        
        + .avatar-wrapper {
            margin-top: 5rem;

            @media screen and (max-width: 991px) {
                margin-top: 2rem;
            }
        }
    }
}
