/**
*
* Blockquote
*
**/

.blockquote {
    position: relative;
    margin: 1em 0;
    padding: rem(48px);

    &:before,
    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        z-index: 1;
        display: block;
        width: 48px;
        height: 48px;
        background: url(/images/icons/format-quote-white.svg) center center no-repeat / 48px auto;
    }

    &:before {
        left: 0;
        transform: rotateY(180deg);
    }

    &:after {
        right: 0;
    }

    &--margin-tablet {

        @media all and (min-width: 991px) and (max-width: 1199px) {
            margin: {
                left: 20px;
                right: 20px;
            }
        }
    }

    &__name {
        margin: 18px 0 0 0;
        font-size: rem(20px);
        font-weight: 700;
    }

    &__position {
        margin: 18px 0 0 0;
        font-size: rem(20px);
    }

    > p {
        margin: 0;
        font-size: rem(24px);
        line-height: rem(36px);
    }
}