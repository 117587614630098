/*==========  COLORS  ==========*/
// Color helper classes are next to color definitions in _utils.scss
@each $colorName, $color in $colors {
    .color-#{$colorName} {
        color: $color;

        &-transparent {
            color: rgba($color, 0.5);
        }
    }
}

/*==========  GENERIC  ==========*/
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}
.text-upper {text-transform: uppercase;}
.text-no-underline { text-decoration: none;}
.z-index {position: relative; z-index: 1;}

.right {float: right;}
.left {float: left;}
.nowrap {white-space: nowrap;}
.single-word {word-spacing: 9999px; }
.underline {text-decoration: underline; }
.relative {position: relative;}
.full-width {width: 100%;}
.link {
    padding: 0;
    background-color: transparent;
    font: {
        size: rem(20px);
        weight: 700;
    }
    line-height: rem(20px);
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.75;
    }

    &-small {
        color: inherit;
        font: {
            size: rem(14px);
            weight: 400;
        }
        text-decoration: underline;

        transition: opacity 0.3s;

        &:hover {
            opacity: 0.75;
        }
    }

    &-inherit {
        font: {
            size: inherit;
            weight: inherit;
        }
        line-height: inherit;
        text-decoration: underline;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.75;
        }
    }
}

.center-on-mobile {
    @media screen and (max-width: 991px) {
        text-align: center;
    }
}

.center-lg {

    @media screen and (max-width: 1199px) {
        text-align: center;
    }
}

.center-xl {

    @media screen and (max-width: 1440px) {
        text-align: center;
    }
}

.pull-up-over-header {
    position: relative;
    z-index: 3;
    margin-top: -100px;

    @media screen and (max-width: 991px) {
        margin-top: -160px;
    }

    &-sm {
        position: relative;
        z-index: 3;
        margin-top: -100px;
    
        @media screen and (max-width: 991px) {
            margin-top: -70px;
        }
    }
}

$gapSizes: (0: 0, 2: 0.2, 5: 0.5, 10: 1, 15: 1.5, 20: 2, 25: 2.5, 30: 3, 35: 3.5, 40: 4, 45: 4.5, 50: 5, 60:6, 70: 7, 80: 8);

@each $var, $size in $gapSizes {
    $value: #{$size}em;

    /*==========  MARGINS  ==========*/
    .mt-#{$var}em {margin-top: $value}
    .mb-#{$var}em {margin-bottom: $value}
    .ml-#{$var}em {margin-left: $value}
    .mr-#{$var}em {margin-right: $value}

    /*==========  PADDING  ==========*/
    .pt-#{$var}em {padding-top: $value}
    .pb-#{$var}em {padding-bottom: $value}
    .pl-#{$var}em {padding-left: $value}
    .pr-#{$var}em {padding-right: $value}

    .pall-#{$var}em {padding: $value}
}

/*========= RWD HELPERS =========*/
@media screen and (min-width: 200px) and (max-width: 568px) {
    .hide-on-mobile {
        display: none;
    }
}

@media screen and (min-width: 569px) and (max-width: 767px) {
    .hide-on-sm,
    .hide-on-sm-down {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .hide-on-md,
    .hide-on-md-down {
        display: none;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .hide-on-lg,
    .hide-on-lg-down {
        display: none;
    }
}

@media all and (max-width: 991px) {

    .hide-on-tablet {
        display: none;
    }
}

/*========= HIDING =========*/

/*
 * Hide visually and from screen readers:
 */
.hidden {display: none !important;}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.hiddenlabel {
    position: absolute;
    z-index: -99;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {visibility: hidden;}
.text-lg {
    @include dynamicFontSize(22, 28, 480, 1600);
    line-height: 1;
}

.text-bold {
    font-weight: 700 !important;
}

.img-padded {
    padding: 10px;
    max-height: 120px;
}

.mt-md-0em {

    @media all and (max-width: 991px) {
        margin-top :0em;
    }
}

.pb-md-0em {

    @media all and (max-width: 991px) {
        padding-bottom: 0!important;
    }
}

.font-italic {font-style: italic;}

.full-height {
    height:  calc(100vh - 100px );
    overflow: hidden;

    @media all and (min-width: 768px) and (max-width: 1279px) {
        height: calc( 100vh - 80px);
    }

    @media all and (max-width: 767px) {
        overflow: visible;
        height: 100%;
    }
}