.checkbox {
    z-index: 0;
    position: relative;
    display: inline-block;
    margin: {
        top: 0.4rem;
        bottom: 0.4rem;
    }
    color: $colorMain;
    font-size: 18px;
    line-height: 1.5;

    .bg-wine &,
    .bg-red &,
    .form--red &,
    .form--wine & {
        color: white;
    }

    > input {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        z-index: -1;
        position: absolute;
        left: -10px;
        top: -8px;
        display: block;
        margin: 0;
        border-radius: 50%;
        width: 46px;
        height: 46px;
        background-color: rgba($colorMain, 0.6);
        box-shadow: none;
        outline: none;
        opacity: 0;
        transform: scale(1);
        pointer-events: none;
        transition: opacity 0.3s, transform 0.2s;

        &:checked,
        &:indeterminate {
            background-color: $colorWine;

            + span {
                &:before {
                    border-color: $colorWine;
                    background-color: $colorWine;
                }

                &:after {
                    border-color: $colorMain;
                }
            }
        }

        &:indeterminate {
            + span {
                &:after {
                    border-left: none;
                    transform: translate(4px, 3px);
                }
            }
        }

        &:focus {
            opacity: 0.12;
        }

        &:active {
            opacity: 1;
            transform: scale(0);
            transition: transform 0s, opacity 0s;

            + span {
                &:before {
                    border-color: rgba($colorMain, 0.6);
                    background-color: rgba($colorMain, 0.6);
                }
            }
        }

        &:disabled {
            opacity: 0;

            + span {
                color: rgba($colorMain, 0.38);
                cursor: initial;

                &:before {
                    border-color: currentColor;
                }
            }
        }

        &:checked,
        &:indeterminate {
            + span {
                &:before {
                    border-color: rgba($colorMain, 0.6);
                    background-color: white;
                }
            }
        }
    }

    > span {
        display: inline-block;
        width: 100%;
        cursor: pointer;
        line-height: 1.7;

        &:before {
            content: "";
            display: inline-block;
            box-sizing: border-box;
            margin: 3px 11px 3px 1px;
            border: solid 1px; /* Safari */
            border-color: rgba($colorMain, 0.6);
            background-color: #fff;
            border-radius: 2px;
            width: 24px;
            height: 24px;
            vertical-align: top;
            transition: border-color 0.2s, background-color 0.2s;

            .bg-wine &,
            .bg-red &,
            .form--red &,
            .form--wine & {
                border: 0;
            }
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            left: 4px;
            width: 14px;
            height: 8px;
            border: solid 2px transparent;
            border-right: none;
            border-top: none;
            transform: translate(3px, 4px) rotate(-45deg);
        }
    }

    &--small {
        > span {
            line-height: 2.3;
            font-size: 14px;
        }
    }

    &:hover {
        > input {
            opacity: 0.04;

            &:focus {
                opacity: 0.16;
            }
        }
    }
}
