@font-face {
    font-family: 'SofiaPro';
    font-display: block;
    src: url('../fonts/36E2D1_0_0.eot');
    src: url('../fonts/36E2D1_0_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/36E2D1_0_0.woff2') format('woff2'),
         url('../fonts/36E2D1_0_0.woff') format('woff'),
         url('../fonts/36E2D1_0_0.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'SofiaPro';
    font-display: block;
    src: url('../fonts/36E2D1_3_0.eot');
    src: url('../fonts/36E2D1_3_0.eot?#iefix') format('embedded-opentype'),
         url('../fonts/36E2D1_3_0.woff2') format('woff2'),
         url('../fonts/36E2D1_3_0.woff') format('woff'),
         url('../fonts/36E2D1_3_0.ttf') format('truetype');
    font-weight: 400;
}
 