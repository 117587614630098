
.filter {

    &--with-dropdown {
        opacity: 0;
        visibility: hidden;
        transition: 0.5s 0.5s opacity, 0.5s 0.5s visibility;

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    &__col[class*=col-] {
        @media all and(min-width: 992px) {
            padding-bottom: 0;
        }
    }

    &__select {
        height: 70px;

        + .sbHolder {
            height: 70px;

            .sbToggle {
                height: 70px;
                width: 46px;
                
                &.sbToggleOpen {
                    + .sbSelector {
                        border-bottom: 0;
                        border-radius: 35px 35px 0 0;
                        transition: none;
                    }
                }
            }

            .sbSelector {
                height: 70px;
                line-height: 36px;
                border-radius: 35px;
                padding: 16px 46px 16px 36px;
                box-shadow: 0 10px 20px rgba(0,0,0,.2);
                color: rgba($colorMain, 0.5);

                font: {
                    size: rem(24px);
                    weight: 700;
                }

                &:after {
                    width: 36px;
                    height: 36px;
                    margin-top: -18px;
                    background: url('/images/icons/chevron-down.svg') center center no-repeat;
                    background-size: 100% auto;
                }
            }

            .sbOptions {
                top: 70px !important;
                padding: {
                    top: 8px !important;
                    bottom: 8px !important;
                }
                border-radius: 0 0 35px 35px;
                box-shadow: 0 10px 20px rgba(0,0,0,.2);

                li {
                    padding: 0 !important;
                }

                a {
                    padding: {
                        left: 36px;
                        right: 36px;
                    }
                    transition: opacity 0.3s;

                    &:hover {
                        opacity: 0.7;
                    }

                    font: {
                        size: rem(24px);
                        weight: 700;
                    }
                }
            }
        }
    }

    &__search {
        display: flex;
        align-items: stretch;
        flex-flow: row nowrap;
        height: 100%;
        width: 100%;
        border-radius: 35px;
        box-shadow: 0 10px 20px rgba(0,0,0,.2);

        input {
            width: 100%;
            padding: 16px 36px 16px 36px;
            border-radius: 35px 0 0 35px;
            border: 0;
            font: {
                size: rem(24px);
                weight: 700;
            }

            &:focus {
                outline: none;
            }
        }

        button {
            padding: 0;
            border-radius: 0 35px 35px 0;
            background-color: #fff;

            &:hover {
                svg {
                    opacity: 0.7;
                }
            }

            svg {
                margin: {
                    left: 24px;
                    right: 24px;
                }
                transition: opacity 0.3s;
            }
        }
    }
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }