
.l-submenu {
    position: fixed;
    top: 100px;
    left: 0;
    z-index: 4;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;

    background-color: $colorLight;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

    transition: transform 0.5s, box-shadow 0.5s;

    @media all and (max-width: 1200px) {
        
        overflow-x: auto;
         -webkit-overflow-scrolling: touch;
    }

    @media screen and (max-width: 1279px) {
        top: 80px;
    }

    + .l-header {
        margin-top: 60px;
    }

    &--left-align {
        justify-content: flex-start;
    }


    &__list {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        height: 100%;
        max-width: 100%;

        &-item {
            display: flex;
            align-items: center;
            height: 100%;
        }

        &-link {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;

            text-align: center;
            
            margin: 0 40px;
            padding: 0 8px;
            font-size: 18px;
            color: $colorMain;
            transition: opacity 0.3s;

            @media all and (max-width: 1200px) {
                white-space: nowrap;
            }

            @media all and (max-width: 1023px) {
                margin: 0 20px;
            }

            &:hover {
                opacity: 0.7;
            }

            &:after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background-color: $colorGold;
                opacity: 0;
                transition: opacity 0.2s;
            }

            &.active {
                font-weight: 700;

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    .btn-back {
        margin-left: 22px;
    }

    .page-scrolled & {
        transform: translateY(-100px);
        box-shadow: none;

        @media screen and (max-width: 1279px) {
            transform: translateY(-80px);
        }
    }
}