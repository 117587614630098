.shell {
    position: relative;
    line-height: 1;

    span {
        position: absolute;
        padding: 31px 24px 13px 24px;
        color: #ccc;
        pointer-events: none;
        z-index: 0;
        white-space: nowrap;

        @supports (-moz-appearance:none) {
            padding-top: 29px;
        }

        i { 
            font-style: normal;
            /* any of these 3 will work */
            color: transparent;
            opacity: 0;
            visibility: hidden;
        }
    }
  }
  
  input.masked,
  .shell span {
    font-size: 1rem;
    background-color: transparent;
    text-transform: uppercase;
  }


  input.masked {
      font-size: 1rem;

      &.form__input--white {
          background-color: #fff;
      }
  }