.timeline {
    @extend .gallery;

    .owl-stage-outer {
        margin-left: -20%;

        @media all and (min-width: 480px) and (max-width: 1280px) {
            margin-left: 0;
        }

        @media all and (max-width: 479px) {
            margin-left: 10%;
        }
    }

    .owl-carousel .owl-item img {
        width: 80%;
    }

    &__headline {
        width: 73%;
        margin: 0 auto;

        &[class*="headline-"] {
            margin: 0 auto 3rem auto;
        }

        @media all and (min-width: 481px) and (max-width: 1280px) {
            width: 67%;
        }

        @media all and (max-width: 480px) {
            width: 80%;
        }
    }

    .owl-carousel .owl-nav .owl-prev {

        @media all and (max-width: 767px) {
            top: 18%;
        }
    }

    .owl-carousel .owl-nav .owl-next {
        @media all and (max-width: 767px) {
            top: 18%;
        }
    }

    .owl-item {

        &:not(:last-child) {

            .timeline-slide__img {
    
                &:after {
                    content: "";
                    margin-top: 40px;
                    display: block;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $colorWhite;
                }
            }
        }
    }

    .owl-carousel .owl-stage-outer {

        @media all and (max-width: 480px) {
            overflow: visible;
        }
    }
}

.timeline-slide {

    &__img {
        &--empty {
            width: 80%;

            &-inside {
                height: 0;
                overflow: hidden;
                padding-top: 305px / 542px * 100%;
                position: relative;
            }
        }
    }

    &__info {
        position: relative;
        margin-top: 100px;
        width: 80%;
        
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: -75px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 2px solid $colorWhite;
            background-color: $colorWine;
            z-index: 1;
        }
    }
}