
.ico-label {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;

    &--align-top {
        align-items: flex-start;
    }

    &__icon {
        margin-right: 0.5rem;
    }

    &__txt {
        width: 100%;

        font: {
            size: rem(14px);
            weight: 400;
        }

        > p {
            margin: 0;
            padding: 0;
        }

        a:not([class]) {
            color: inherit;
        }

        &--inherit {
            font-size: inherit;
        }
    }

    &-list {
        display: flex;
        flex-flow: row wrap;
        margin: 1rem 0;

        .l-header__article & {
            margin-top: .5rem;
        }

        > * {
            margin-right: 2rem;
        }

        &--large {
            display: block;
            border-radius: 6px;
            background-color: rgba(#fff, 0.6);

            > * {
                display: flex;
                flex-flow: row nowrap;
                width: 100%;
                margin: 0;
                padding: 2rem;

                + * {
                    border-top: 1px solid rgba(#000, 0.1);
                }
            }

            .ico-label__txt {
                margin-left: 2rem;
                font-size: rem(32px);

                @media screen and (min-width: 1401px) and (max-width: 1600px) {
                    margin-left: 1rem;
                    font-size: rem(28px);
                }

                @media screen and (min-width: 801px) and (max-width: 1400px) {
                    margin-left: 1rem;
                    font-size: rem(22px);
                }

                @media screen and (min-width: 200px) and (max-width: 800px) {
                    margin-left: 1rem;
                    font-size: rem(22px);
                }
            }
        }

        &--space-between {
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            margin: 2rem 0 0 0;
        }
    }

    + .btn {
        margin: {
            top: 1rem;
            bottom: 1rem;
        }
    }
}
