/**
*
* Content (text stylisation connected to CMS WYSIWYG editor)
*
**/
.content {
    p,
    li,
    td {
        font-size: rem(18px);
        line-height: rem(27px);
        font-weight: 400;
    }

    .content--smokyWhite,
    &--smokyWhite p {
        color: $colorSmokyWhite;
    }
    
    .content__medium-text,
    &--medium-text p,
    &--medium-text li {
        font-size: 16px;
        line-height: 20px;
    }
    
    .content__small-text,
    &--small-text p,
    &--small-text li {
        font-size: 14px;
        line-height: 21px;
    }

    .content__large-text,
    &--large-text p,
    &--large-text li {
        font-size: rem(24px);
        line-height: rem(36px);
    }

    p:not(:last-child) {
        margin: 0;
        // padding-bottom: .5em;
        padding-bottom: 16px;
    }

    ul:not(:last-child),
    ol:not(:last-child) {
        padding-bottom: 1em;
    }

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p + ul,
    p + ol {
        //margin-top: -.7em !important;
    }

    /* uls, ols */
    ul {
        @extend .list-ul;
    }

    ol {
        @extend .list-ol;
    }

    blockquote {
        @extend .blockquote;
    }

    &-standout-section {
        padding: rem(48px);
        font-size: rem(24px);
        line-height: rem(36px);

        > p {
            margin: 0;
        }
    }

    &-two-cols {
        @media all and (min-width: 992px) {
            column-count: 2;
            column-gap: 20px;
        }
    }

    h1 {
        @extend .headline-1;
    }

    h2 {
        @extend .headline-2;
    }

    h3 {
        @extend .headline-3;
    }

    h4 {
        @extend .headline-4;
    }

    h5,
    h6 {
        @extend .headline-5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: rem(24px) 0 20px 0 !important;
    }

    iframe {
        max-width: 100%;
    }
}