/**
*
* Forms
*
**/

.form {
    position: relative;
    margin-bottom: 3rem;
    padding: 42px;

    @media screen and (max-width: 991px) {
        padding: rem(24px);
    }

    &--padded {
        padding: {
            left: 96px;
            right: 96px;
        }

        @media all and (max-width:640px) {
            padding: {
                left: 25px;
                right: 25px;
            }
        }
    }

    &--narrow {
        padding: 24px;
    }

    &--no-padding {
        padding: 0;
    }

    &--large-bottom-padding {
        padding-bottom: 72px;

        &.block {
            padding-bottom: 72px;
        }
    }

    fieldset {
        @include clearfix();
    }

    &-container {
        position: relative;
        margin-top: -105px;
        box-shadow: 0 10px 20px rgba(0,0,0,.2);
        z-index: 2;

        @media all and (min-width: 735px) and (max-width: 1023px) {
            margin-top: -72px;
        }

        @media all and (min-width: 501px) and (max-width: 734px) {
            margin-top: -99px;
        }

        @media all and (min-width: 369px) and (max-width: 500px) {
            margin-top: -93px;
        }

        @media all and (max-width: 368px) {
            margin-top: -117px;
        }
    }

    &-wrapper {

        padding: {
            left: calc(( (100vw - 1240px) / 12) + 48px );
            right: calc(((100vw - 1240px) / 12) + 48px );
            top: 96px;
            bottom: 96px;
        }

        @media all and (min-width: 481px) and (max-width: 1240px) {
            padding: {
                left: 50px;
                right: 50px;
            }
        }

        @media all and (max-width: 480px) {
            padding: {
                left: 24px;
                right: 24px;
                top: 48px;
                bottom: 48px;
            }
        }
    }


    &__group {
        position:relative;
        margin-bottom: 0.6rem;
        padding-bottom: 22px;

        &--not-required {
            padding-bottom: 12px;
        }

        &--mask {
            .shell span {
                z-index: 1;
            }
        }
    }

    &__input,
    &__textarea {
        font-size: rem(16px);
        padding: 27px 24px 13px 24px;
        display:block;
        width: 100%;
        border: none;
        color: $colorMain;
        border: 1px solid rgba($colorMain, 0.5);
        border-radius: 0;

        &:focus {
            outline:none;
            caret-color: $colorMain;
        }

        &:focus,
        &.filled {

            /* active state */
            ~ .form__label {
                top: 8px;
                font-size: 12px;
                color: $colorWine;
            }
        }

        &--no-border {
            border: 0;
        }

        &--stripe {
            padding: 16px;
        }
    }

    &__label {
        color: rgba($colorMain, 0.65);
        font-size: 16px;
        font-weight: 400;
        position:absolute;
        pointer-events:none;
        left: 24px;
        top: 18px;
        transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease, padding 0.2s ease;
    }

    &__textarea {
        width: 100%;
        min-height: 8em;
    }

    &__required {
        position: absolute;
        bottom: 0;
        left: 24px;
        font-size: 12px;
        color: $colorWine;
    }




    &__row {
        display: table;
        width: 100%;
        clear:both;
        padding: 6px 0;

        &--3-cols {
            @media screen and (min-width: 768px) {
                display: grid;
                grid-template-columns: 2fr 4fr 4fr;
                gap: 1rem;
            }
        }

        &:last-child {
            padding-bottom: 0;

            .form__group--not-required {
                padding-bottom: 0;
            }
        }

        &--hidden {
            display: none;
        }

        &-btns {
            text-align: right;

            .link {
                margin-right: 5%;

                @media all and (max-width: 518px) {
                    margin-bottom: 30px;
                }
            }
        }

        &--single-col {
            display: block;
        }

        &__left,
        &__right {
            display: table-cell;
            vertical-align: middle;

            @media all and (min-width: 200px) and (max-width: 600px) {
                display: table;
            }

            @media screen and (min-width: 992px) and (max-width: 1199px) {
                display: table;
            }
        }

        &__left {
            width: 48%;
            padding-right: 2%;

            @media all and (min-width: 200px) and (max-width: 600px) {
                width: 100%;
                padding: 6px 0;
            }

            @media screen and (min-width: 992px) and (max-width: 1199px) {
                width: 100%;
                padding: 6px 0;
            }

            &--sm-50 {

                @media screen and (min-width: 992px) and (max-width: 1199px) {
                    width: 48%;
                    padding-right: 2%;
                    display: table-cell;
                    vertical-align: middle;
                }
            }
        }

        &__right {
            width: 48%;
            padding-left: 2%;

            @media all and (min-width: 200px) and (max-width: 600px) {
                width: 100%;
                padding: 6px 0;
            }

            @media screen and (min-width: 992px) and (max-width: 1199px) {
                width: 100%;
                padding: 6px 0;
            }

            &--sm-50 {

                @media screen and (min-width: 992px) and (max-width: 1199px) {
                    width: 48%;
                    padding-right: 2%;
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            &--text-right {
                text-align: right;

                @media all and (min-width: 200px) and (max-width: 600px) {
                    text-align: left;
                }

                @media screen and (min-width: 992px) and (max-width: 1199px) {
                    text-align: left;
                }
            }
        }

        &__middle {
            vertical-align: middle;
        }

        &__three-cols {
            // display: flex;
            // width: 100%;
            // flex-flow: row wrap;

            columns: 3;

            @media screen and (min-width: 1200px) and (max-width: 1778px) {
                columns: 2;
            }

            @media screen and (min-width: 1025px) and (max-width: 1199px) {
                columns: 1;
            }

            @media all and (min-width: 768px) and (max-width: 1024px) {
                columns: 2;
            }

            @media all and (min-width: 200px) and (max-width: 767px) {
                columns: 1;
            }

            > .checkbox {
                flex: 0 1 33%;
                min-width: 170px;

                @media all and (min-width: 768px) and (max-width: 1024px) {
                    min-width: 100%;
                }

                @media all and (min-width: 200px) and (max-width: 767px) {
                    min-width: 100%;
                }

                span {
                    padding-right: 6px;
                }
            }
        }
    }

    &--shadow {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }

    &--wine {
        background-color: $colorWine;

        .form__response--error {
            background-color: white;
            border: 1px solid $colorHalfDark;

            p {
                color: $colorWine;
            }
        }
    }

    &--red {
        background-color: $colorRed;

        .form__response--error {
            background-color: white;
            border: 1px solid $colorHalfDark;

            p {
                color: $colorRed;
            }
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-right: inherit;
        text-align: right;
        transform: translateY(50%);

        @media (min-width: 200px) and (max-width: 991px) {
            text-align: center;
            padding-left: inherit;
        }


        &--align-center {
            text-align: center;
            padding-left: inherit;
        }

        &--align-left {
            text-align: left;
            padding-left: inherit;
        }

        &--space-between {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: inherit;
        }

        .popup__close {
            position: static;
            
            
            &.btn--red {
                background-color: $colorRed;
            }

            &.btn--wine {
                background-color: $colorWine;
            }

            &.btn--white {
                background-color: #fff;
            }

            &.btn--black {
                background-color: $colorMain;
            }
        }
    }

    &__header {
        padding-bottom: 8px;
        margin-bottom: 24px;
        border-bottom: 2px solid $colorMain;
    }

    &--wine,
    &--red {
        .form__input,
        .form__textarea {
            border: 0;

            + .form__label {
                background-color: #fff;
            }
        }

        .form__textarea {
            ~ .form__label {
                width: 100%;
                left: 0;
                top: 0;
                padding-left: 24px;
                padding-top: 18px;
                padding-bottom: 4px;
            }


            &:focus,
            &.filled {
    
                /* active state */
                ~ .form__label {
                    width: 100%;
                    left: 0;
                    top: 0;
                    padding-left: 24px;
                    padding-top: 8px;
                }
            }
        }

        .form__required {
            color: $colorWhite;
        }

        .form__header {
            border-bottom: 1px solid $colorWhite;
        }

        color: #fff;
    }


}


.shell {
    /* active state */
    ~ .form__label {
        top: 8px;
        font-size: 12px;
        color: $colorWine;
    }
}

// Checkbox & Radio inputs
.form__radio,
.form__checkbox {
    position: absolute;
    left: -999em;
}

.form__radio-label,
.form__checkbox-label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.form__radio-label:before,
.form__checkbox-label:before {
    display: inline-block;
    position: relative;
    top: 2px;
    left: -2px;
    content:'';
    width: 25px;
    height: 23px;
    background: url(../images/formelements.png) top left no-repeat;
}

.form__checkbox + .form__checkbox-label:before {
    background-position: 0 -25px;
}

.form__checkbox:checked + .form__checkbox-label:before {
    background-position: 0 0 ;
}

.form__radio + .form__radio-label:before {
    background-position: -25px -25px;
}

.form__radio:checked + .form__radio-label:before {
    background-position: -25px 0;
}

// Select list
.form__select {
    position: relative;
    padding:0;
    margin:0;
    max-width: 600px;

    select {
        width:115%;
        background-color:transparent;
        background-image:none;
        -webkit-appearance: none;
        border:none;
        box-shadow:none;
    }
}


.form__response {
    margin-bottom: 12px;


    &--ok {
        background: green;
    }

    &--error {
        background: $colorRed;
    }

    p {
        margin: 0;
        padding: 12px 20px 10px 20px;

        font-size: rem(18px) !important;
        color: #fff;
        text-shadow: none;
    }
}


::-webkit-input-placeholder {
    color: rgba($colorMain, 0.5);
}

:-moz-placeholder {
    color: rgba($colorMain, 0.5);
}

::-moz-placeholder {
    color: rgba($colorMain, 0.5);
}

:-ms-input-placeholder {
    color: rgba($colorMain, 0.5);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.form--two-column {
    @media screen and (min-width: 768px) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    > * {
        &.form__group {
            @media screen and (min-width: 768px) {
                flex-grow: 1;
                flex: 0 1 48%;
            }

            &--full-width {
                flex: 1 1 100%;

                .form__checkbox-label {
                    padding: {
                        bottom: 4px;
                    }
                }
            }
        }

        &:not(.form__group) {
            flex: 1 1 100%;
        }
    }
}

.event-form {

    .form__input {
        padding-left: 16px;
        padding-right: 16px;
    }

    .form__label {
        left: 16px;
    }


    .form__label--select {
        position: relative;
        top: 0;
        left: 0;
        padding: 6px 16px 0;
        font-size: 12px;
        color: $colorRed;
        background-color: white;
        display: inline-block;
        width: 100%;
        z-index: 30;

        & ~ .sbHolder {
            margin-top: -10px;
        }
    }

    .form__select {
        background-color: white;
        color: black;
        padding: 18px;
        border: none;
        outline: none;
    }

    .checkbox__container {
        padding: 12px;
        vertical-align: top;
        margin-bottom: -14px;
        background-color: rgba(0,0,0,.25);

        .checkbox {
            width: 24px;
            height: 24px;
            border-radius: 2px;
            margin: 0 12px 0 0;
            background-color: white;
            display: none;

            &__label {
                font-size: 16px;
                font-weight: 300;
                vertical-align: top;
                line-height: 1.7;
                padding-left: 32px;
                position: relative;
                display: block;
                cursor: pointer;

                &:before {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    background-color: white;
                    border-radius: 1px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    z-index: 20;
                    top: 2px;
                    left: 0px;
                    width: 14px;
                    height: 8px;
                    border: solid 3px black;
                    border-right: none;
                    border-top: none;
                    transform: translate(3px, 4px) rotate(-45deg);
                    transition: opacity 0.2s;
                    opacity: 0;
                }
            }

            &:checked + .checkbox__label:after {
                opacity: 1;
            }
        }

        & + .form__required {
            bottom: -11px;
        }
    }

    .form__required {
        left: 16px;
    }

    .sbHolder {
        border: none;
        .sbSelector {
            border-radius: 0;
            border: none;
        }

        .sbToggleOpen+.sbSelector {
            border-radius: 0;
        }

        .sbOptions {
            border-radius: 0;
            z-index: 40;

        }
    }

}