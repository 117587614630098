.section {
    position: relative;

    &--font-sm {

        @media all and (min-width: 992px) and (max-width: 1366px) {
            font-size: 14px;
        }

        @media all and (max-height: 800px) {
            font-size: 14px;
        }
    }

    &--bg-shape {

        &:before {
            content: " ";
            position: absolute;
            top: 0;
            left: 10%;
            height: 100%;
            width: 50%;
            background: url(/images/icons/background-shape-red.svg) no-repeat 0/100% auto;
    
            @media screen and (max-width: 991px) {
                width: 100%;
                left: 0;
            }
        }

        &.bg-wine:before {
            background: url(/images/icons/background-shape-on-wine.svg) no-repeat 0/100% auto;
        }

        &.bg-white,
        &.bg-light {

            &:before {
                background: url(/images/icons/background-shape.svg) no-repeat 0/100% auto;
                opacity: .1;
            }
        }

        > * {
            position: relative;
            z-index: 2;
        }

        &-left {

            &:before {
                left: 0;
            }
        }
    }

    // default padding
    padding: {
        top: 60px;
        bottom: 60px;
    }

    @media all and (max-width: 991px) {
        padding: {
            top: 30px;
            bottom: 30px;
        }   
    }

    // padding variations
    &--p-small {
        &-top {
            padding-top: 20px;
        }

        &-bottom {
            padding-bottom: 20px;
        }
    }

    &--p-medium {
        &-top {
            padding-top: 40px;
        }

        &-bottom {
            padding-bottom: 40px;
        }
    }

    &--p-large {
        &-top {
            padding-top: 80px;
        }

        &-bottom {
            padding-bottom: 80px;
        }
    }

    &--p-none {
        &-top {
            padding-top: 0;
        }

        &-bottom {
            padding-bottom: 0;
        }
    }

    // header + footer
    &__header {
        padding-bottom: 2em;
    }

    &__footer {
        padding-top: 2em;
    }
    
    // useful
    &--vertically-aligned {
        display: flex;
        align-items: center;
    }
    
    &--btns-on-bottom {
        [class*="col-"] {
            padding-bottom: 100px;
        }

        .btn {
            position: absolute;
            bottom: 30px;
        }
    }

    &--padded {

        &-mob {

            @media all and (max-width: 992px) {
                padding: {
                    top: 20px;
                    right: 25px;
                    left: 25px;
                }
            }
        }
    }

    &__footer-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        padding-right: inherit;
        text-align: center;
        transform: translateY(50%); 

        &--bt {
            transform: translateY(-25%); 

            @media all and (max-width: 991px) {
                transform: translateY(10%); 
            }
        }
    }
}