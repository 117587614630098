
.internal-grid {

    &-wrapper {
        display: flex;
        flex-flow: row wrap;
        margin-top: 2rem;

        > * {
            margin-bottom: 20px;
            width: calc(50% - 10px);

            @media screen and (min-width: 1441px) and (max-width: 1600px) {
                width: 100%;
            }

            @media screen and (min-width: 992px) and (max-width: 1399px) {
                width: 100%;
            }

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }

            &:nth-child(odd) {
                margin-right: 10px;

                @media screen and (min-width: 1441px) and (max-width: 1600px) {
                    margin-right: 0;
                }
    
                @media screen and (min-width: 992px) and (max-width: 1399px) {
                    margin-right: 0;
                }
    
                @media screen and (max-width: 767px) {
                    margin-right: 0;
                }
            }

            &:nth-child(even) {
                margin-left: 10px;

                @media screen and (min-width: 1441px) and (max-width: 1600px) {
                    margin-left: 0;
                }
    
                @media screen and (min-width: 992px) and (max-width: 1399px) {
                    margin-left: 0;
                }
    
                @media screen and (max-width: 767px) {
                    margin-left: 0;
                }
            }
        }
    }
}