
.radio-list {

    &__input {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        z-index: -999;

        &:checked {
            + .radio-list__label {
                background-color: $colorWine;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
                color: #fff;

                .product__selected {
                    opacity: 1;
                    visibility: visible;
                }

                .product__info {
                    color: #fff;
                }
            }
        }
    }

    &__item {
        margin-top: 24px;
    }

    &__label {
        display: block;
        padding: 24px;
        background-color: $colorLight;
        cursor: pointer;
        transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
    }

    .product__info {
        transition: color 0.3s;
    }
}