.team-member {
    position: relative;
    overflow: hidden;
    height: 0;
    min-height: 0;
    padding-top: 100%;

    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    box-shadow: 0 7px 20px rgba(0,0,0,.2);

    color: #fff;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        transform: scale(1.02);
        box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.2);
    }
}

.team-member-info {
    padding: 18px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(185, 34, 34, 0.75);
    font-weight: 700;
    z-index: 2;

    @media all and (min-width: 1025px) and (max-width: 1650px) {
        padding: 10px;
    }

    @media all and (min-width: 768px) and (max-width: 1024px) {
        padding: 10px;
    }

    @media all and (max-width: 767px) {
        display: none;
    }

    &__name {
        @extend %commonStyles;
        font-size: 24px;

        @media all and (min-width: 1025px) and (max-width: 1650px) {
            font-size: 18px;
        }

        @media all and (min-width: 992px) and (max-width: 1024px) {
            font-size: 16px;
        }

        @media all and (max-width: 991px) {
            font-size: 14px;
        }
    }

    &__pos {
        @extend %commonStyles;
        font-size: 20px;

        @media all and (min-width: 1025px) and (max-width: 1650px) {
            font-size: 16px;
        }

        @media all and (min-width: 992px) and (max-width: 1024px) {
            font-size: 14px;
        }

        @media all and (max-width: 991px) {
            font-size: 12px;
        }
    }

    p {
        margin: 0;
    }

    &--dark {
        background-color: rgba($colorMain, 0.75);

        .team-member-info__name {
            text-align: center;
            font-size: 18px;
        }
    }
}

.team-member-headline {
    padding: 18px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(18, 18, 18, 0.7);
    font-weight: 700;
    z-index: 2;

    @media all and (min-width: 768px) and (max-width: 1024px) {
        padding: 10px;
    }

    @media all and (max-width: 767px) {
        display: none;
    }

    p {
        margin: 0;

        @media all and (min-width: 768px) and (max-width: 1024px) {
            font-size: 14px;
        }
    }
}