/**
*
* Top header
*
**/

.l-header {
    position: relative;
    z-index: 2;
    padding: rem(96px) 0;

    @media all and (max-width: 991px) {
        padding-top: 45px;
    }

    &--no-padded {
        padding: 0;
    }

    &--full-height {
        height:  calc(100vh - 100px );
        overflow: hidden;
        background-color: $colorMain;

        @media all and (min-width: 768px) and (max-width: 1279px) {
            height: calc( 100vh - 80px);
        }

        @media all and (max-width: 767px) {
            overflow: visible;
            height: 100%;
        }
    }

    &__col-scroll {
        height: calc(100vh - 100px);
        overflow-y: scroll;

        @media all and (min-width: 768px) and (max-width: 1279px) {
            height: calc( 100vh - 80px);
        }

        @media all and (max-width: 767px) {
            overflow: visible;
            height: 100%;
        }

        &::-webkit-scrollbar-track {
            background-color: $colorRed;
        }
    
        &::-webkit-scrollbar {
            width: 8px;
            background-color:  $colorRed;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: $colorWhite;
            border-radius: 4px;
        }

        @media all and (min-width: 768px) {
            transform: translateX(100%);
            transition: transform 0.6s ease;
        }

        &.show {
            @media all and (min-width: 768px) {
                transform: translateX(0);
            }   
        }
    }

    &__article {
        padding: 30px 20px;

        &--publication {
            > p {
                margin-bottom: .5rem;
            }
        }

        .row {
            >div:first-child { padding-right: 5px}
            >div:last-child { padding-left: 8px}
        }

        &-pic {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            margin: 0;

            &-wrap {
                @media screen and (min-width: 768px) and (max-width: 1024px) {
                    max-width: 150px;
                    margin: 0 auto;
                }
            }
        } 
    }

    &__logo {
        margin-bottom: 15px;
        width: 250px;

        @media all and (min-width: 568px) and (max-width: 1024px) {
            width: 200px;
        }

        @media all and (max-width: 567px) {
            width: 180px;
        }
    }

    &__headline {
        padding: 85px 85px 40px;
        position: absolute;
        bottom: 0;
        max-width: 1150px;

        @media all and (min-width: 768px) {
            transform: translateX(-100px);
            opacity: 0;
            transition: transform 0.6s ease, opacity 0.6s ease;
        }

        &.show {
            @media all and (min-width: 768px) {
                transform: translateX(0);
                opacity: 1;
            }   
        }

        @media all and (min-width: 568px) and (max-width: 1150px) {
            padding: {
                left: 50px;
                right: 50px;
            }
        }

        @media all and (max-width: 567px) {
            padding: {
                left: 15px;
                right: 15px;
            }
        }
    }

    &__img {
        position: relative; 
        height: calc(100vh - 100px);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left center;

        @media all and (max-width: 1279px) {
            height: calc( 100vh - 80px);
        }

        &--curtain {

            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s 0.25s ease, visibility 0.5s 0.25s ease;

            &.show {
                opacity: 1;
                visibility: visible;  
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $colorHalfDark;
            }
        }
    }

    &__content {

        &-flex {
            display: flex;
            flex-flow: wrap row;
            justify-content: space-between;
            align-items: center;

            @media all and (max-width: 576px) {
                flex-direction: column;
                justify-content: center;
                text-align: center;

                @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
                    display: block;
                }
            }

        }

        &-headline {

            @media all and (min-width: 577px) {
                margin-bottom: 0!important;
            }
        }
    }

    &--padded {
        padding: {
            left: 15px;
            right: 15px;
        }
    }

    &--pb-lg {

        padding: {
            top: rem(96px);
            bottom:  rem(165px);
        }

        @media all and (max-width: 767px) {
            padding-bottom: rem(120px);
        }
    }

    &--footer {
        @media screen and (max-width: 991px) {
            padding: {
                left: 25px;
                right: 25px;
                bottom: 45px;
            }
        }
    }

    &:not(.l-header--footer):not(.l-header--pb-lg):not(.l-header--pb-mob):not(.l-header--no-padded) {
        @media screen and (max-width: 991px) {
            padding: {
                left: 25px;
                right: 25px;
                bottom: 25px;
            }
        }
    }

    &--bg-shape {
        position: relative;
        z-index: 2;
        background-color: $colorWine;

        &:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 10%;
            height: 100%;
            width: 30%;
            background: url(/images/icons/background-shape.svg) no-repeat left center / 100% auto;
            opacity: 0.2;

            @media screen and (max-width: 991px) {
                width: 100%;
                left: 0;
            }
        }

        > * {
            position: relative;
            z-index: 3;
        }
    }

    &-half-shape {
        position: relative;
        z-index: 1;
        margin-top: 100px;
        margin-bottom: rem(36px);

        @media screen and (max-width: 991px) {
            margin-top: 25px;
        }

        &:before {
            content: ' ';
            position: absolute;
            top: -100px;
            left: 0;
            z-index: 0;
            height: calc(50% + 101px);
            width: 100%;
            background-color: $colorWine;
        }

        &:after {
            content: ' ';
            position: absolute;
            top: -100px;
            left: 10%;
            z-index: 0;
            height: calc(50% + 101px);
            width: 30%;
            background: url(/images/icons/background-shape.svg) no-repeat left center / 100% auto;
            opacity: 0.2;

            @media screen and (max-width: 991px) {
                width: 100%;
                left: 0;
            }
        }

        > * {
            position: relative;
            z-index: 2;
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-right: inherit;
        text-align: right;
        transform: translateY(50%);

        @media screen and (max-width: 991px) {
            position: relative;
            z-index: 2;
            transform: none;
            padding: 12px 0 0 0;
        }
    }

    &__svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        opacity: 1;
        visibility: visible;

        transition: opacity 0.5s 0s ease, visibility 0.5s 0s ease;

        &.hide {
            opacity: 0;
            visibility: hidden;
        }
    }
}
