.contact-item {
    @include dynamicFontSize(16, 18, 480, 1600);
    color: $colorWine;
    transition: all 0.3s;
    text-decoration: underline;

    &:hover {
        color: #da3434;
        text-decoration: none;

        svg {
            fill: #da3434;
        }
    }

    svg {
        margin-right: 5px;
        width: 24px;
        height: 24px;
        fill: $colorWine;
        transition: all 0.3s;
    }

    &--lg {
        @include dynamicFontSize(18, 24, 480, 1600);
    }
}

.contact-address {
    @include dynamicFontSize(18, 24, 480, 1600);
    display: flex;

    svg {
        margin-right: 5px;
        width: 30px;
        height: 30px;
        fill: $colorWine;
        transition: all 0.3s;
    }

    p {
        margin: 0;
    }
}
