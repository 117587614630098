.placecard {
    z-index: 9999;
    position: absolute;

    &-wrapper {
        position: absolute;
        top: 0;
    }

    &__container {
        margin: 10px;
        padding: 9px 4px 9px 11px;
        width: 100%;
        min-width: 285px;
        display: flex;
        box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
        background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box;
        border-radius: 2px 2px 2px 2px;
        font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
        overflow: hidden;
    }

    &__left {
        flex: 1 1 70%;
    }

    &__right {
        flex: 1 1 30%;
        text-align: center;
    }

    &__business-name {
        margin: 0;
        height: 19px;
        cursor: default;
        text-overflow: ellipsis;
        white-space: nowrap;
        perspective-origin: 100px 9.5px;
        transform-origin: 100px 9.5px;
        font: normal normal 500 normal 14px / normal Roboto, Arial;
        overflow: hidden;
    }

    &__info {
        color: rgb(91, 91, 91);
        cursor: default;
        height: 32px;
        column-rule-color: rgb(91, 91, 91);
        perspective-origin: 100px 16px;
        transform-origin: 100px 16px;
        border: 0px none rgb(91, 91, 91);
        font: normal normal normal normal 12px / normal Roboto, Arial;
        margin: 6px 0px 0px;
        outline: rgb(91, 91, 91) none 0px;

        @media all and (max-width: 420px) {
            margin-top: 0;
        }
    }

    &__directions-icon {
        margin-bottom: 5px;
        background: rgba(0, 0, 0, 0) url("https://maps.gstatic.com/mapfiles/embed/images/entity11.png") repeat scroll 0px 0px / 70px 210px padding-box border-box;
        background-position: 70px 210px; //save - 96px 187px
        height: 22px;
        width: 22px;
        margin-right: auto;
        margin-left: auto;
    }

    &__directions-link {
        display: block;
        height: 43px;
        color: rgb(58, 132, 223);
        text-decoration: none;
    }

    &__view-large {
        display: block;
        color: rgb(58, 132, 223);
        text-decoration: none;
    }
}
  