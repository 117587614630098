/**
*
* Button
*
**/

.btn {
    @include inline-block();
    margin: {
        top: 0.4rem;
        bottom: 0.4rem;
    }
    padding: 24px 36px; //23px 36px
    border-radius: 35px;
    background-color: $colorMain;
    color: $colorWhite;
    transition: box-shadow 0.3s, background-color 0.3s;
    font: {
        size: rem(24px);
        weight: 700;
    }
    line-height: 1;
    text-align: center;

    @media (min-width: 200px) and (max-width: 991px) {
        font-size: rem(20px);
    }

    &:hover {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        background-color: lighten($colorMain, 10%);
    }

    &:not(:first-of-type):not(:last-of-type),
    &:not(:last-of-type) {
        margin-right: 10px;

        @media all and (max-width: 640px) {
            margin-left: 0;
        }
    } 

    // + .btn {
    //     margin-left: 10px;

    //     @media all and (max-width: 640px) {
    //         margin-left: 0;
    //     }
    // }

    &--full-width {
        width: 100%;
    }

    &--big {
        padding: 24px 36px;
        font-size: rem(32px);
        border-radius: 40px;
    }

    &--small {
        padding: 16px 24px;
        font-size: rem(20px);
    }

    &--compact {
        padding: 21px 32px;
        border-radius: 50px;
        
        @media all and (min-width: 1200px) {
            width: 135px;
        }
    }

    &--card {
        padding: 13px 24px 15px 24px;
        border-radius: 0;
        font-size: rem(20px);
        color: $colorRed;

        &.btn--white {
            color: $colorRed;
        }
    }

    &--shadow {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

        &:hover {
            box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15);
        }
    }

    &--wide {
        @media (min-width: 400px) {
            min-width: 292px;
        }
        
        text-align: center;
    }

    &--super-wide {
        min-width: 454px;
        text-align: center;

        @media all and (min-width: 992px) and (max-width: 1120px) {
            min-width: 100%;
        }

        @media all and (max-width: 640px) {
            min-width: 100%;
        }
    }


    @each $colorName, $color in $colors {
        &--#{$colorName} {
            background-color: $color;

            @if map-has-key($bgColorsWhiteFont, $colorName) {
                color: #fff;
            }

            @else {
                color: $colorMain;
            }

            &:hover {

                @if $colorName == white {
                    background-color: darken($color, 10%);
                }

                @else {
                    background-color: lighten($color, 10%);
                }


            }
        }
    }

    
    &--fly {
        position: relative;
        top: 210px;

        @media all and (max-width: 1199px) {
            top: auto;
        }
    }

    &--disabled {
        color: $colorHalfDark;
        position: relative;
        padding-left: 80px;
        pointer-events: none;

        &:before {
            content: ' ';
            position: absolute;
            top: 16px;
            left: 30px;
            display: block;
            width: 36px;
            height: 36px;

            background: url(/images/icons/lock.svg) center no-repeat;
            background-size: 100% auto;

            opacity: 0.36;
        }
    }

    &-back {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        font-size: 18px;
        color: $colorMain;

        span {
            transition: opacity 0.3s;
        }

        svg {
            transition: transform 0.3s;
        }

        &:hover {
            span {
                opacity: 0.7;
            }

            svg {
                transform: translateX(-6px);
            }
        }
    }
}
