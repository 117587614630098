/**
*
* Breadcrumbs
*
**/

.breadcrumbs {
    clear: both;
    padding: 1em 0;
    color: #999;

    li {
        display: inline-block;

        &:not(:last-child):after {
            content: '/';
            padding: 0 10px;
            color: #dedede;
        }
    }

    a {
        color: $colorMain;

        &:hover {
            color: #555;
        }
    }
}