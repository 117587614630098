/**
*
* Top navigation
*
**/

.l-main {
    overflow: hidden;

    &--padding {
        padding: rem(96px) 0 rem(48px) 0;

        @media screen and (max-width: 991px) {
            padding: rem(26px) 0 rem(13px) 0;
        }

        &-sm {
            padding: rem(48px) 0;
        }
    }
}