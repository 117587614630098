
@each $width in $columnsWidthSet {
    .col-#{$width} {
        width: $width * 1%;

        @media all and (max-width: 768px) {
            width: 100%;
        }

        .text-center & {
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
}