
.fixed-img {
    height: 460px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    &--logo {
        position: relative;
        z-index: 0;
        background-color: $colorWine;

        &:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 10%;
            height: 100%;
            width: 30%;
            background: url(/images/icons/background-shape.svg) no-repeat left center / 100% auto;
            opacity: 0.2;

            @media screen and (max-width: 991px) {
                width: 100%;
                left: 0;
            }
        }
    }
}
