
.sbHolder {
    height: 42px;
    position: relative;
    width: 100%;
    text-align: left;

    &.selected {
        .sbSelector {
            color: $colorMain;
        }
    }

    + .js-select + .sbHolder,
    + .js-select-filter + .sbHolder {
        margin-left: 1em;

        @media all and (max-width: 768px) {
            margin-left: 0;
        }
    }
}

.sbsbHolder-inline + .sbHolder {
    width: 50%;

    @media all and (max-width: 1024px) {
        width: 90%;
    }
}


.sbHolder:focus .sbSelector{

}
.sbSelector{
    display: block;
    height: 42px;
    left: 0;
    line-height: 42px;
    outline: none;
    overflow: hidden;
    position: absolute;
    padding: 0 40px 0 1.0em;
    top: 0;
    width: 100%;

    border-radius: 44px;
    border: 1px solid rgba($colorMain, 0.1);
    background-color: #fff;

    white-space: nowrap;
    text-overflow: ellipsis;

    transition: border-radius 0.2s 0.35s;

    &:after {
        content: '';

        position: absolute;
        right: 18px;
        top: 50%;
        margin-top: -8px;

        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        background-color: $colorGold;
        background: url('/images/icons/menu-down.svg') center center no-repeat;
        background-size: 24px auto;

        margin-left: 0.5em;
    }
}
.sbSelector:link,
.sbSelector:visited,
.sbSelector:hover{
    color: $colorMain;
    outline: none;
    text-decoration: none;
}
.sbToggle {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    display: block;
    height: 42px;
    width: 42px;
    outline: none;

    &.sbToggleOpen {
        + .sbSelector {
            border-bottom: 0;
            border-radius: 21px 21px 0 0;
            transition: none;
        }
    }
}

.sbHolderDisabled{
    background-color: #3C3C3C;
    border: solid 1px #00a0e3;
}
.sbHolderDisabled .sbHolder{

}
.sbHolderDisabled .sbToggle{

}
.sbOptions{
    background-color: $colorWhite;
    list-style: none;
    left: 0;
    margin: 0;
    padding: 0 !important;
    position: absolute;
    top: 30px;
    width: 100%;
    z-index: 2;
    overflow-y: auto;

    border-radius: 0 0 21px 21px;
    border: 1px solid rgba($colorMain, 0.1);
}
.sbOptions li{
    padding: 0 1em !important;
    transition: background 0.2s ease;

    &:before {
        background: none !important;
        display: none !important;
    }

    &:hover {
        background-color: #fff;
    }
}
.sbOptions a {
    display: block;
    outline: none;
    padding: 7px 0 7px 3px;
}
.sbOptions a:link,
.sbOptions a:visited{
    color: $colorMain;
    text-decoration: none;
}
.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus{
}
.sbOptions li.last a{
    border-bottom: none;
}
.sbOptions .sbDisabled{
    color: #999;
    display: block;
    padding: 7px 0 7px 3px;
}
.sbOptions .sbGroup{
    color: #EBB52D;
    display: block;
    font-weight: bold;
    padding: 7px 0 7px 3px;
}
.sbOptions .sbSub{
    padding-left: 17px;
}

.hidden + .sbHolder {
    display: none;
}
