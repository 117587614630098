.bg {
    @each $colorName, $color in $colors {
        &-#{$colorName} {
            background-color: $color;

            @if map-has-key($bgColorsWhiteFont, $colorName) {
                color: $colorWhite;
            }
        }
    }

    &-half-grey-white {
        overflow: hidden; 

        > div {
            &:not(.bg-half-grey-white__target) {
                position: relative;
                z-index: 1;
            }
        }

        &__target {
            position: relative;
            z-index: 0;

            &:before {
                content: "";
                position: absolute;
                top: calc(-150% - 10px);
                left: 0;
                display: block;
                width: 100%;
                height: 200%;
                background-color: $colorLight;
            }
        }
    }

    &-half-wine {
        overflow: hidden;

        > div {
            &:not(.bg-half-wine__target) {
                position: relative;
                z-index: 1;
            }
        }

        &__target {
            position: relative;
            z-index: 0;

            &:before {
                content: "";
                position: absolute;
                top: calc(-150% - 10px);
                left: 0;
                display: block;
                width: 100%;
                height: 200%;
                background-color: $colorWine;
            }
        }
    }

    &-wine {
        .color-wine:not(.btn--white) {
            color: $colorWhite;
        }

        .svg-fill-wine {
            fill: #fff;
        }

        .chip--wine {
            background-color: $colorWhite;
            color: $colorWine;
        }

        .chip__text {
            span {
                color: $colorWhite;

                &:last-child {
                    color: rgba($colorWhite, 0.7);
                }
            }
        }

        .list-arrows {        
            li {
                
                &:before {
                    background-image: url(/images/icons/menu-right-white.svg);
                }
            }
        }
    }

    &-rose {

        .svg-fill-rose {
            fill: #fff;
        }
    }

    &-shape {
        position: relative;

        &:before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: url(/images/icons/background-shape.svg) no-repeat 0/100% auto;
            opacity: .075;
        }

        &-narrow {
            position: relative;

            &:before {
                content: " ";
                position: absolute;
                top: 0;
                left: 13%;
                height: 100%;
                width: 74%;
                background: url(/images/icons/background-shape.svg) no-repeat 0/100% auto;
                opacity: .075;
            }
        }

        &--set-behind {

            & > * {
                position: relative;
                z-index: 1;
            }
        }   
    }

    &-light-white {
        position: relative;
        background-color: #fff;

        &:before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            height: 50%;
            width: 100%;
            background-color: $colorLight;
        }
    }
}