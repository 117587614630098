/**
*
* Pagination
*
**/

.pagination {
    width: 100%;
    clear: both;
    padding: 1em 0;
    text-align: center;

    li,
    &__item {
        display: inline-block;
    }

    &__item {
        padding: .3em 1rem;
        margin: 0.1em 0;
        color: $colorMain;
        font-size: rem(20px);
        font-weight: 700;

        background-color: $colorLight;

        transition: background-color 0.3s ease;

        .disabled & {
            opacity: 0.3;
        }
    }

    &__item--active,
    a:hover {
        background-color: $colorRed;
        color: #fff;
    }

    &--no-uderline{

        a {
            text-decoration: none;
        }
    }
}