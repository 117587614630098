
.burger {
    display: flex;
    align-items: center;
    height: 100%;
    padding: {
        left: 24px;
        right: 24px;
    }
    background-color: transparent;

    @media screen and (min-width: 1280px) {
        display: none;
    }
}